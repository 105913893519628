import { formatAmount } from "../../../utils/formatAmount";
import UnknownTokenIcon from "./../../../assets/unknownToken.svg";

export const TokenList = ({ tokenList, setToken }) => {
  return (
    <div className="transaction-content">
      <div className="small-text">Assets</div>
      <div className="token-list">
        {tokenList.map((token) => {
          return (
            <button key={token.id} className="token" onClick={() => setToken(token)}>
              {token.icon ? (
                <img
                  src={token.icon}
                  alt={`${token.name} token`}
                  className="transaction-token-icon"
                />
              ) : (
                <UnknownTokenIcon />
              )}
              <div className="uppercase token-balance">
                <div>{token.name}</div>
                <div>
                  {formatAmount(token.amount)} {token.name}
                </div>
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
};
