import { useContext, useEffect, useState } from "react";
import { Button, Loader, Spinner, Drawer } from "../../../components";
import AdaIcon from "./../../../assets/unknownToken.svg";
import { useNavigate } from "react-router-dom";
import { shortAddress } from "../../../utils/shortAddress";
import { Send } from "../../../assets/send";
import { formatAmount } from "../../../utils/formatAmount";
import { NetworkContext } from "../../../contexts/networkContext";
import { MaxAmount } from "./maxAmount/maxAmount";
import { NetworkIcon } from "../../../components/networkIcon";
import { NavHeader } from "../../../components/navHeader/navHeader";
import { LoginDrawer } from "./../../login/loginDrawer";
import { InfoRow } from "../transactionInfo/infoRow";

export const ConfirmationPage = () => {
  const navigate = useNavigate();
  const {
    fee,
    estimateFee,
    submitTransaction,
    addPendingTransaction,
    loading,
    getAssets,
    assets,
    nexusQuerier,
    primeWallet,
  } = useContext(NetworkContext);

  const [loader, setLoader] = useState(false);
  const [disclaimer, setDisclaimer] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [transaction] = useState(JSON.parse(window.localStorage.getItem("transaction")));
  const { network, address, token } = transaction;
  const [amount, setAmount] = useState(Number(window.localStorage.getItem("amount")));
  const [asset, setAsset] = useState();
  const [max, setMax] = useState(amount >= token.balance);

  useEffect(() => {
    if (nexusQuerier && primeWallet) {
      getAssets();
    }
  }, [nexusQuerier, primeWallet]);

  useEffect(() => {
    const calculateFee = (address, asset, amount) => {
      estimateFee(network, address, asset.hash, amount);
    };

    let asset = assets.find((el) => el.name === token.token && el.network === network);
    if (asset) {
      setAsset(asset);
      setMax(Number(amount) === Number(asset?.amount));

      if (Number(asset?.amount) < amount) {
        calculateFee(address, asset, asset.amount);
        setDisclaimer(true);
      } else calculateFee(address, asset, amount);
    }
  }, [assets]);

  useEffect(() => {
    if (Number(asset?.amount) < amount) {
      setAmount(Number(asset?.amount) - Number(fee));
    }
  }, [fee]);

  const sendTransaction = async (mnemonic) => {
    setLoader(true);
    submitTransaction(transaction, asset.hash, amount, mnemonic)
      .then((rec) => {
        addPendingTransaction(rec.hash, transaction, amount);
        window.localStorage.removeItem("transaction");
        window.localStorage.removeItem("amount");
        window.localStorage.removeItem("max");
        navigate("/list-transactions");
      })
      .catch((err) => {
        console.log(err);
        navigate("/error");
      });
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          {loading && <Spinner />}
          <div className="transaction-container">
            <div className="transaction-header">
              <NavHeader {...{ label: "Confirm" }} />
            </div>

            <div className="transaction-selection">
              <div className="transaction-content">
                <Send />
                <div className="subtitle">
                  Confirm transaction to address &nbsp;
                  {shortAddress(address)}
                </div>
              </div>

              <div className="details-content">
                <InfoRow
                  label={
                    <div className="network-details gap-10">
                      <span>Total Value</span>
                      {max && <MaxAmount />}
                    </div>
                  }
                  value={
                    <div className="inline center-align">
                      <TokenIcon {...{ icon: token.icon }} />
                      &nbsp; &nbsp;
                      <Amount {...{ amount, token: token.token.name }} />
                    </div>
                  }
                />

                <InfoRow label="Transaction type" value="send" />

                <InfoRow label="Network" value={<NetworkIcon {...{ network }} />} />

                <div className="divider" />

                <InfoRow
                  label="Fee estimate"
                  value={<Amount {...{ amount: fee, token: token.token.name }} />}
                />
              </div>
            </div>
            <span className="disclaimer">
              Review the above before confirming. Once made, your transaction is irreversible.
            </span>

            <Button
              label="Confirm"
              disabled={amount <= 0}
              action={() => setConfirmation((val) => !val)}
            />
            <LoginDrawer
              {...{ isOpen: confirmation, toggleDrawer: setConfirmation, action: sendTransaction }}
            />

            <DisclaimerDrawer isOpen={disclaimer} toggleDrawer={setDisclaimer} />
          </div>
        </>
      )}
    </>
  );
};

const DisclaimerDrawer = ({ isOpen, toggleDrawer }) => {
  return (
    <Drawer {...{ isOpen, toggleDrawer }}>
      <NavHeader {...{ label: "Balance changed", action: () => toggleDrawer((val) => !val) }} />
      Since you started executing this transaction your balance has changed and is lower than the
      amount you wanted to send. We updated the send amount to be the maximum amount you can send at
      the moment.
    </Drawer>
  );
};

const Amount = ({ amount, token }) => {
  return (
    <>
      {amount && <span>{formatAmount(amount)}</span>} &nbsp; {token}
    </>
  );
};

const TokenIcon = ({ icon }) => {
  return (
    <>
      {icon ? <img src={icon} alt="" className="small-icon" /> : <AdaIcon className="small-icon" />}
    </>
  );
};
