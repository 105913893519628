// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout {
  border-top: 1px solid #262827;
  background: var(--Apex-fusion-black, #131313);

  display: inline-flex;
  justify-content: space-between;

  position: -webkit-sticky;
  position: sticky;
  bottom: 0;

  padding: 18px 45px 14px;
  width: 100%;
  height: 88px;
  z-index: 0;
}

.layout-item {
  align-items: center;
  background: transparent;
  border: none;
  color: #8a8e92;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 12px;

  text-align: center;
  font-size: 11px;
  font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/components/navbar/navbar.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,6CAA6C;;EAE7C,oBAAoB;EACpB,8BAA8B;;EAE9B,wBAAwB;EACxB,gBAAgB;EAChB,SAAS;;EAET,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,SAAS;;EAET,kBAAkB;EAClB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".layout {\n  border-top: 1px solid #262827;\n  background: var(--Apex-fusion-black, #131313);\n\n  display: inline-flex;\n  justify-content: space-between;\n\n  position: -webkit-sticky;\n  position: sticky;\n  bottom: 0;\n\n  padding: 18px 45px 14px;\n  width: 100%;\n  height: 88px;\n  z-index: 0;\n}\n\n.layout-item {\n  align-items: center;\n  background: transparent;\n  border: none;\n  color: #8a8e92;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n  gap: 12px;\n\n  text-align: center;\n  font-size: 11px;\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
