import { mnemonicToEntropy } from "bip39";
import {
  Address, AssetName,
  BaseAddress,
  BigNum,
  Bip32PrivateKey, Certificate, Certificates, Ed25519KeyHash,
  hash_transaction,
  LinearFee,
  make_vkey_witness, MultiAsset,
  NetworkInfo,
  RewardAddress, ScriptHash,
  StakeCredential, StakeDelegation, StakeRegistration,
  Transaction,
  TransactionBuilder,
  TransactionBuilderConfigBuilder, TransactionHash, TransactionInput,
  TransactionOutput, TransactionUnspentOutput, TransactionUnspentOutputs,
  TransactionWitnessSet,
  Value,
  Vkeywitnesses,
} from "@emurgo/cardano-serialization-lib-browser";
import { decode } from "cbor-web";

function harden(num) {
  return 0x80000000 + num;
}

export default class CardanoTransactor {
  #protocolParams = {
    linearFee: {
      minFeeA: "47",
      minFeeB: "158298",
    },
    minUtxo: "0",
    poolDeposit: "0",
    keyDeposit: "0",
    maxValSize: 5000,
    maxTxSize: 16384,
    priceMem: 5.77,
    priceStep: 0.00721,
    coinsPerUtxoWord: "4310",
  };

  #rootKey;
  #accountKey;
  #stakeKey;

  #networkId;
  #magicNumber;
  #blockfrostURL;
  #ogmiosURL;

  constructor(blockfrostURL, ogmiosURL, networkId, magicNumber, mnemonic) {
    this.#blockfrostURL = blockfrostURL;
    this.#ogmiosURL = ogmiosURL;
    this.#setProtocolParams();

    this.#networkId = networkId;
    this.#magicNumber = magicNumber;

    const entropy = mnemonicToEntropy(mnemonic);
    this.#rootKey = Bip32PrivateKey.from_bip39_entropy(
      Buffer.from(entropy, "hex"),
      Buffer.from("")
    );

    this.#accountKey = this.#rootKey
      .derive(harden(1852)) // purpose
      .derive(harden(1815)) // coin type
      .derive(harden(0)); // account #0

    this.#stakeKey = this.#accountKey
      .derive(2) // chimeric
      .derive(0)
      .to_public();
  }

  #setProtocolParams() {
    fetch(new URL("/epochs/latest/parameters", this.#blockfrostURL))
      .then((res) => {
        return res.json();
      })
      .then((params) => {
        this.#protocolParams.linearFee.minFeeA = params.min_fee_a;
        this.#protocolParams.linearFee.minFeeB = params.min_fee_b;
        this.#protocolParams.minUtxo = params.min_utxo;
        this.#protocolParams.poolDeposit = params.pool_deposit;
        this.#protocolParams.keyDeposit = params.key_deposit;
        this.#protocolParams.maxValSize = params.max_val_size;
        this.#protocolParams.maxTxSize = params.max_tx_size;
        this.#protocolParams.priceMem = params.price_mem;
        this.#protocolParams.priceStep = params.price_step;
        this.#protocolParams.coinsPerUtxoWord = params.coins_per_utxo_word;
      });
  }

  getReceiveAddress(index = 0) {
    let utxoPubKey = this.#accountKey
      .derive(0) // external
      .derive(index)
      .to_public();

    const baseAddress = BaseAddress.new(
      NetworkInfo.new(this.#networkId, this.#magicNumber).network_id(),
      StakeCredential.from_keyhash(utxoPubKey.to_raw_key().hash()),
      StakeCredential.from_keyhash(this.#stakeKey.to_raw_key().hash())
    );

    return baseAddress.to_address().to_bech32("addr_test");
  }

  getChangeAddress(index = 0) {
    let utxoPubKey = this.#accountKey
      .derive(0) // external
      .derive(index)
      .to_public();

    const changeAddress = BaseAddress.new(
      NetworkInfo.new(this.#networkId, this.#magicNumber).network_id(),
      StakeCredential.from_keyhash(utxoPubKey.to_raw_key().hash()),
      StakeCredential.from_keyhash(this.#stakeKey.to_raw_key().hash())
    );

    return changeAddress.to_address().to_bech32("addr_test");
  }

  getStakeAddress() {
    const stakeAddress = RewardAddress.new(
      NetworkInfo.new(this.#networkId, this.#magicNumber).network_id(),
      StakeCredential.from_keyhash(this.#stakeKey.to_raw_key().hash())
    );

    return stakeAddress.to_address().to_bech32("stake_test");
  }

  #privateKey() {
    return this.#accountKey.derive(0).derive(0).to_raw_key();
  }

  async getUTXOs() {
    let utxos = TransactionUnspentOutputs.new();

      let res = await fetch(new URL(`/addresses/${this.getReceiveAddress()}/utxos`, this.#blockfrostURL));
      let parsedUTXOs = await res.json();

      parsedUTXOs.forEach((parsedUTXO) => {
        utxos.add(this.#convertToTransactionUnspentOutput(parsedUTXO));
      });


    return utxos;
  }

  #convertToTransactionUnspentOutput(utxo) {
    let scriptHashSize = 28;
    let txIn = TransactionInput.new(TransactionHash.from_hex(utxo.tx_hash), utxo.tx_index);

    let lovelaceAmount = BigNum.zero();
    let multiAsset = MultiAsset.new();
    for (let amount of utxo.amount) {
      if (amount.unit === "lovelace") {
        lovelaceAmount = BigNum.from_str(amount.quantity);
      } else {
        let policyId = ScriptHash.from_hex(amount.unit.substring(0, scriptHashSize));
        let assetName = ScriptHash.from_hex(amount.unit.substring(-scriptHashSize));

        multiAsset.set_asset(
          policyId,
          AssetName.from_hex(assetName.to_hex()),
          BigNum.from_str(amount.quantity)
        );
      }
    }

    let txOut;
    if (multiAsset.len() === 0) {
      txOut = TransactionOutput.new(Address.from_bech32(utxo.address), Value.new(lovelaceAmount));
    } else {
      txOut = TransactionOutput.new(
        Address.from_bech32(utxo.address),
        Value.new_with_assets(lovelaceAmount, multiAsset)
      );
    }

    return TransactionUnspentOutput.new(txIn, txOut);
  }

  // soul dilemma cream detect heart cool super tree mean robust universe fabric drop plastic paper harvest hen ship hidden bag shoe number tilt warm
  async stake(poolId="pool1trevp502sq5yv22l4gv46qkhyzmgv5smwqu8myjxeecwud7vmc9") {
    const txBuilder = TransactionBuilder.new(
      TransactionBuilderConfigBuilder.new()
        .fee_algo(
          LinearFee.new(
            BigNum.from_str(this.#protocolParams.linearFee.minFeeA.toString()),
            BigNum.from_str(this.#protocolParams.linearFee.minFeeB.toString())
          )
        )
        .pool_deposit(BigNum.from_str(this.#protocolParams.poolDeposit))
        .key_deposit(BigNum.from_str(this.#protocolParams.keyDeposit))
        .coins_per_utxo_word(BigNum.from_str(this.#protocolParams.coinsPerUtxoWord))
        .max_value_size(this.#protocolParams.maxValSize)
        .max_tx_size(this.#protocolParams.maxTxSize)
        .prefer_pure_change(true)
        .build()
    );

    console.log(this.#protocolParams.linearFee.minFeeA.toString())
    console.log(this.#protocolParams.linearFee.minFeeB.toString())

    const certificates = Certificates.new()

    const stakeRegistrationCert = Certificate.new_stake_registration(
      StakeRegistration.new(
        StakeCredential.from_keyhash(this.#stakeKey.to_raw_key().hash())
      )
    )

    const stakeDelegationCert = Certificate.new_stake_delegation(
      StakeDelegation.new(
        StakeCredential.from_keyhash(this.#stakeKey.to_raw_key().hash()),
        Ed25519KeyHash.from_bech32(poolId),
      )
    )

    // certificates.add(stakeRegistrationCert)
    certificates.add(stakeDelegationCert)
    txBuilder.set_certs(certificates)

    let txUnspentOutputs = await this.getUTXOs();
    txBuilder.add_inputs_from(txUnspentOutputs, 2);

    console.log(txUnspentOutputs)

    // txBuilder.add_required_signer(this.#stakeKey.to_raw_key().hash())
    // txBuilder.set_fee(BigNum.from_str("177991"))

    // calculate the min fee required and send any change to an address
    txBuilder.add_change_if_needed(Address.from_bech32(this.getReceiveAddress()));

    const txBody = txBuilder.build();
    const txHash = hash_transaction(txBody);
    const witnesses = TransactionWitnessSet.new();
    const vkeywitnesses = Vkeywitnesses.new();
    const vkeyWitness1 = make_vkey_witness(txHash, this.#privateKey());
    const vkeyWitness2 = make_vkey_witness(txHash, this.#accountKey.derive(2).derive(0).to_raw_key())
    vkeywitnesses.add(vkeyWitness1);
    vkeywitnesses.add(vkeyWitness2);
    witnesses.set_vkeys(vkeywitnesses);

    const transaction = Transaction.new(
      txBody,
      witnesses,
      undefined // transaction metadata
    );

    const hex = transaction.to_hex()
    console.log(decode(hex))
    return hex;
  }

  signTx(tx) {
    const txHash = hash_transaction(tx);
    const witnesses = TransactionWitnessSet.new();

    const vkeys = Vkeywitnesses.new();
    const vkey = make_vkey_witness(txHash, this.#privateKey());
    vkeys.add(vkey);
    witnesses.set_vkeys(vkeys);

    const transaction = Transaction.new(
      tx,
      witnesses,
      undefined // transaction metadata
    );

    return transaction.to_hex();
  }

  async submitTx(tx) {
    const client = new WebSocket(this.#ogmiosURL);

    await new Promise((resolve) => {
      client.addEventListener("open", () => resolve(true), { once: true });
    });

    client.send(
      JSON.stringify({
        jsonrpc: "2.0",
        method: "submitTransaction",
        params: { transaction: { cbor: tx } },
      })
    );

    return new Promise((resolve, reject) => {
      client.addEventListener(
        "message",
        (response) => {
          try {
            const { result } = JSON.parse(response.data);

            if (result.transaction.id) {
              resolve(result.transaction.id);
            } else {
              reject(""); //add failure handler here
            }

            client.close();
          } catch (error) {
            reject(error);
          }
        },
        { once: true }
      );
    });
  }
}
