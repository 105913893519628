// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-container {
  display: flex;
  height: calc(100% - 88px);
  flex-direction: column;
  overflow: scroll;
  padding: 50px 25px 30px;
  gap: 70px;
}

.settings-header {
  padding: 20px 0;
}

.settings-red-action {
  color: var(--red);
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 140%;
}

.settings-inline {
  align-items: center;
  display: inline-flex;
}

.settings-grow {
  align-items: center;
  display: flex;
  flex: 1;
  gap: 15px;
}

.settings-red {
  color: var(--red);
}

.drawer-header {
  align-items: flex-start;
  display: inline-flex;
  gap: 15px;
}

.drawer-padding-top-3 {
  padding-top: 3px;
}

.settings-drawer-content {
  padding-bottom: 47px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/settings/settings.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,sBAAsB;EACtB,gBAAgB;EAChB,uBAAuB;EACvB,SAAS;AACX;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,OAAO;EACP,SAAS;AACX;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,uBAAuB;EACvB,oBAAoB;EACpB,SAAS;AACX;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".settings-container {\n  display: flex;\n  height: calc(100% - 88px);\n  flex-direction: column;\n  overflow: scroll;\n  padding: 50px 25px 30px;\n  gap: 70px;\n}\n\n.settings-header {\n  padding: 20px 0;\n}\n\n.settings-red-action {\n  color: var(--red);\n  text-align: center;\n  font-size: 18px;\n  font-weight: 500;\n  line-height: 140%;\n}\n\n.settings-inline {\n  align-items: center;\n  display: inline-flex;\n}\n\n.settings-grow {\n  align-items: center;\n  display: flex;\n  flex: 1;\n  gap: 15px;\n}\n\n.settings-red {\n  color: var(--red);\n}\n\n.drawer-header {\n  align-items: flex-start;\n  display: inline-flex;\n  gap: 15px;\n}\n\n.drawer-padding-top-3 {\n  padding-top: 3px;\n}\n\n.settings-drawer-content {\n  padding-bottom: 47px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
