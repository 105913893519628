// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.max-amount {
  border-radius: 56px;
  border: 1.458px solid #077368;
  background: rgba(7, 115, 104, 0.13);

  align-items: center;
  display: inline-flex;
}

.big {
  font-size: 21px;
  gap: 8px;

  padding: 5px 18px 5px 12px;
  width: fit-content;
}

.small {
  font-size: 15px;
  gap: 6px;

  padding: 0 10px 0 7px;
  width: fit-content;
}
`, "",{"version":3,"sources":["webpack://./src/pages/transactions/send/maxAmount/maxAmount.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,6BAA6B;EAC7B,mCAAmC;;EAEnC,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,eAAe;EACf,QAAQ;;EAER,0BAA0B;EAC1B,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,QAAQ;;EAER,qBAAqB;EACrB,kBAAkB;AACpB","sourcesContent":[".max-amount {\n  border-radius: 56px;\n  border: 1.458px solid #077368;\n  background: rgba(7, 115, 104, 0.13);\n\n  align-items: center;\n  display: inline-flex;\n}\n\n.big {\n  font-size: 21px;\n  gap: 8px;\n\n  padding: 5px 18px 5px 12px;\n  width: fit-content;\n}\n\n.small {\n  font-size: 15px;\n  gap: 6px;\n\n  padding: 0 10px 0 7px;\n  width: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
