import "./drawer.css";

export const Drawer = (props) => {
  const { children, isOpen, toggleDrawer = () => {} } = props;
  return (
    <>
      {isOpen && <div className="receive-in-back" onClick={() => toggleDrawer((val) => !val)} />}
      <div className={`drawer ${isOpen && "drawer-open"}`}>{children}</div>
    </>
  );
};
