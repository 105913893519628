import { useState } from "react";
import "./pagination.css";

export const Pagination = (props) => {
  const { pages = 10 } = props;
  const [currentPage, setCurrentPage] = useState(1);

  const showPageNumber = (page) => {
    let show = true;
    if (pages > 5 && page > 2 && page <= pages - 2) show = false;

    return show;
  };

  const Page = ({ page }) => {
    const PageNumber = () => {
      return (
        <div
          className={`pagination-page ${currentPage === page && "pagination-page-active "}`}
          onClick={() => setCurrentPage(page)}
        >
          {page}
        </div>
      );
    };

    if (pages <= 5) return <PageNumber />;
    else if (
      page === 1 ||
      page === pages ||
      currentPage === page ||
      page === currentPage + 1 ||
      page === currentPage - 1
    )
      return <PageNumber />;
    else if (page === currentPage + 2 || page === currentPage - 2) return <div>...</div>;
    else if ((currentPage < 2 || currentPage > pages - 2) && (page === 2 || page === pages - 1))
      return <PageNumber />;

    return <></>;
  };

  return (
    <div className="pagination-container">
      {currentPage !== 1 && <ArrowLeft />}
      {Array.from({ length: pages }, (_, i) => i + 1).map((page) => {
        return <Page {...{ page }} key={page} />;
      })}

      {currentPage !== pages && <ArrowRight />}
    </div>
  );
};

const ArrowRight = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
      <path d="M1.00068 10.9993L6 6L1.00068 1.00068" stroke="#F7F7F7" strokeWidth="0.868535" />
    </svg>
  );
};

const ArrowLeft = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
      <path d="M5.99932 1.00068L1 6L5.99932 10.9993" stroke="#F7F7F7" strokeWidth="0.868535" />
    </svg>
  );
};
