import "./copyBtn.css";
import { useContext } from "react";
import CopyIcon from "./../../assets/copyIcon.svg";
import { ToastContext } from "../../contexts/toastContext";

export const CopyBtn = ({ data }) => {
  const { showToast } = useContext(ToastContext);

  const copy = (data) => {
    navigator.clipboard.writeText(data);
    showToast();
  };

  return (
    <button className="copy" onClick={() => copy(data)}>
      <CopyIcon />
    </button>
  );
};
