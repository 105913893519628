export const Send = ({ filled = false, color, width = 53, height = 55 }) => {
  return (
    <>
      {!filled ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 52 55"
          fill="none"
        >
          <path
            d="M26 36C26 36.5523 26.4477 37 27 37C27.5523 37 28 36.5523 28 36L26 36ZM27.7071 19.2929C27.3166 18.9024 26.6834 18.9024 26.2929 19.2929L19.9289 25.6569C19.5384 26.0474 19.5384 26.6805 19.9289 27.0711C20.3195 27.4616 20.9526 27.4616 21.3431 27.0711L27 21.4142L32.6569 27.0711C33.0474 27.4616 33.6805 27.4616 34.0711 27.0711C34.4616 26.6805 34.4616 26.0474 34.0711 25.6569L27.7071 19.2929ZM28 36L28 20L26 20L26 36L28 36Z"
            fill={color ?? "#F7F7F7"}
          />
          <path d="M14.314 1H1V14.314" stroke={color ?? "#DEE5DF"} strokeWidth="0.768116" />
          <path
            d="M0.999999 40.686L1 54L14.314 54"
            stroke={color ?? "#DEE5DF"}
            strokeWidth="0.768116"
          />
          <path
            d="M37.9985 54L51.3125 54L51.3125 40.686"
            stroke={color ?? "#DEE5DF"}
            strokeWidth="0.768116"
          />
          <path
            d="M51.3125 14.314L51.3125 1L37.9985 0.999999"
            stroke={color ?? "#DEE5DF"}
            strokeWidth="0.768116"
          />
        </svg>
      ) : (
        <svg
          width="71"
          height="71"
          viewBox="0 0 71 71"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="71" height="71" fill="#077368" />
          <path
            d="M35 44C35 44.5523 35.4477 45 36 45C36.5523 45 37 44.5523 37 44L35 44ZM36.7071 27.2929C36.3166 26.9024 35.6834 26.9024 35.2929 27.2929L28.9289 33.6569C28.5384 34.0474 28.5384 34.6805 28.9289 35.0711C29.3195 35.4616 29.9526 35.4616 30.3431 35.0711L36 29.4142L41.6569 35.0711C42.0474 35.4616 42.6805 35.4616 43.0711 35.0711C43.4616 34.6805 43.4616 34.0474 43.0711 33.6569L36.7071 27.2929ZM37 44L37 28L35 28L35 44L37 44Z"
            fill="#F7F7F7"
          />
          <path d="M23.314 9H10V22.314" stroke="#DEE5DF" strokeWidth="0.768116" />
          <path d="M10 48.686L10 62L23.314 62" stroke="#DEE5DF" strokeWidth="0.768116" />
          <path d="M46.9985 62L60.3125 62L60.3125 48.686" stroke="#DEE5DF" strokeWidth="0.768116" />
          <path d="M60.3125 22.314L60.3125 9L46.9985 9" stroke="#DEE5DF" strokeWidth="0.768116" />
        </svg>
      )}
    </>
  );
};
