// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drawer {
  border-radius: 20px 20px 0px 0px;
  background: #242424;

  position: fixed;
  bottom: -1000px;
  left: 0;
  z-index: 10;
  width: 100%;
  padding: 56px 32px 43px;

  -webkit-transition: all 0.5s ease-out allow-discrete;
  transition: all 0.5s ease-out allow-discrete;

  display: flex;
  flex-direction: column;
  gap: 37px;
  max-height: calc(100vh - 10%);
  overflow: auto;
}

.drawer-open {
  bottom: 0px;
}

.receive-in-back {
  position: absolute;
  opacity: 0.5;
  background: var(--Apex-fusion-black);
  height: calc(100% - 50px);
  width: calc(100% - 35px);
}
`, "",{"version":3,"sources":["webpack://./src/components/drawer/drawer.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,mBAAmB;;EAEnB,eAAe;EACf,eAAe;EACf,OAAO;EACP,WAAW;EACX,WAAW;EACX,uBAAuB;;EAEvB,oDAAoD;EACpD,4CAA4C;;EAE5C,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,6BAA6B;EAC7B,cAAc;AAChB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,oCAAoC;EACpC,yBAAyB;EACzB,wBAAwB;AAC1B","sourcesContent":[".drawer {\n  border-radius: 20px 20px 0px 0px;\n  background: #242424;\n\n  position: fixed;\n  bottom: -1000px;\n  left: 0;\n  z-index: 10;\n  width: 100%;\n  padding: 56px 32px 43px;\n\n  -webkit-transition: all 0.5s ease-out allow-discrete;\n  transition: all 0.5s ease-out allow-discrete;\n\n  display: flex;\n  flex-direction: column;\n  gap: 37px;\n  max-height: calc(100vh - 10%);\n  overflow: auto;\n}\n\n.drawer-open {\n  bottom: 0px;\n}\n\n.receive-in-back {\n  position: absolute;\n  opacity: 0.5;\n  background: var(--Apex-fusion-black);\n  height: calc(100% - 50px);\n  width: calc(100% - 35px);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
