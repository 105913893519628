import { React } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import {
  Route,
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
} from "react-router-dom";
import { OnboardingPage } from "./pages/onboarding/onboardingPage";
import { NewTransactionPage } from "./pages/transactions/send/newTransactionPage";
import { AmountPage } from "./pages/transactions/send/amountPage";
import { ConfirmationPage } from "./pages/transactions/send/confirmationPage";
import { DashboardPage } from "./pages/dashboard/dashboardPage";
import { ReceivePage } from "./pages/transactions/receive/receivePage";
import { WalletConnectLayout } from "./layouts/walletConnectLayout";
import { NetworkProvider } from "./contexts/networkContext";
import { WalletConnectQrReaderPage } from "./pages/walletConnect/qrReader";
import * as Sentry from "@sentry/react";
import { ErrorPage } from "./pages/error/errorPage";
import { SettingsPage } from "./pages/settings/settingsPage";
import { Navbar } from "./components";
import { ToastProvider } from "./contexts/toastContext";
import { StakingListPage } from "./pages/staking/stakingListPage";
import { PoolDetailsPage } from "./pages/staking/poolDetailsPage";
import { StakingPromoPage } from "./pages/staking/stakingPromoPage";
import { LoginPage } from "./pages/login/loginPage";
import { ListTransactionsPage } from "./pages/transactions/transactionInfo/listTransactionsPage";
import { SingleTransactionPage } from "./pages/transactions/transactionInfo/singleTransactionPage";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<NetworkProvider />}>
      <Route path="/" element={<OnboardingPage />} />
      <Route path="/error" element={<ErrorPage />} />

      <Route element={<WalletConnectLayout />}>
        <Route element={<Navbar />}>
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/transaction/receive" element={<ReceivePage />} />
          <Route path="/transaction/new" element={<NewTransactionPage />} />
          <Route path="/transaction/amount" element={<AmountPage />} />
          <Route path="/transaction/confirm" element={<ConfirmationPage />} />
          <Route path="/list-transactions" element={<ListTransactionsPage />} />
          <Route path="/list-transactions/:id" element={<SingleTransactionPage />} />
          <Route path="/transaction/receive" element={<ReceivePage />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="/stake" element={<StakingListPage />} />
          <Route path="/stake/:id" element={<PoolDetailsPage />} />
        </Route>
        <Route path="/stake-on-prime" element={<StakingPromoPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/wallet-connect/qr" element={<WalletConnectQrReaderPage />} />
      </Route>
    </Route>
  )
);

Sentry.init({
  dsn: "https://daaeffb0fe66557d4ee02c1b62a8b084@o4507100585852928.ingest.de.sentry.io/4507100795764816",
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  environment: "development",

  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["https://apexfusion.route3.dev"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = document.getElementById("root");
createRoot(root).render(
  <Sentry.ErrorBoundary fallback={<ErrorPage />}>
    <ToastProvider>
      <RouterProvider router={router} />
    </ToastProvider>
  </Sentry.ErrorBoundary>
);
