export class EthereumConnectorMetadata {
  #address;

  chainId;
  namespace;
  methods = ["eth_sendTransaction", "personal_sign"];
  approvalRequiredMethods = ["eth_sendTransaction", "personal_sign"];

  /**
   * @param {string} chainId
   * @param {string} address
   */
  constructor(chainId, address) {
    this.chainId = chainId;
    this.namespace = `eip155:${chainId}`;
    this.#address = address;
  }

  account() {
    return `${this.namespace}:${this.#address}`;
  }
}

export class EthereumConnector {
  #transactor;

  /**
   * @param {import("./transactor").EthereumTransactor} transactor
   */
  constructor(transactor) {
    this.#transactor = transactor;
  }

  async eth_sendTransaction(params) {
    const tx = params[0];
    if (!tx || !tx.from || !tx.data || !tx.to) {
      throw new Error("Invalid transaction");
    }
    tx.value = tx.value ?? "0";

    if (tx.from.toLowerCase() !== this.#transactor.getAddress().toLowerCase()) {
      throw new Error("Invalid from address in eth_sendTransaction");
    }

    const resp = await this.#transactor.sendTransaction(tx);
    return resp.hash;
  }

  async personal_sign(params) {
    const hexMsg = params[0];
    if (!hexMsg) {
      throw new Error("Missing message");
    }
    const msg = toUtf8String(params[0]);

    const address = params[1];
    if (address != this.#transactor.getAddress()) {
      throw new Error("Wrong address in personal_sign params");
    }

    const signature = await this.#transactor.signMessage(msg);
    return signature;
  }
}
