import "./staking.css";
import { PoolFilter } from "./poolFilter";
import { Button, Spinner, CopyBtn } from "./../../components";
import { useNavigate } from "react-router";
import { shortId } from "../../utils/shortId";
import { useContext, useEffect, useState } from "react";
import { formatAda } from "../../utils/formatAda";
import { PoolRow } from "./poolRow";
import { SocialNetwork } from "./socialNetwork";
import { NetworkContext } from "../../contexts/networkContext";
import { BabelFishApi } from "r3-apex-lib";

export const StakingListPage = () => {
  const navigate = useNavigate();
  const [small, setSmall] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pools, setPools] = useState([]);
  const [filteredPools, setFilteredPools] = useState([]);
  const [totalRewards, setTotalRewards] = useState({});
  const [delegations, setDelegations] = useState([]);

  const filterProps = {
    list: pools,
    setList: setFilteredPools,
  };
  const { primeWallet } = useContext(NetworkContext);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => setSmall(window.scrollY > 100));
    }
    if (primeWallet) {
      primeWallet
        .getPools()
        .then((pools) => {
          setPools(pools ?? []);
          setFilteredPools(pools);
          setLoading(false);
        })
        .catch((err) => console.log(`Error occurred wile getting staking pools: ${err}`));

      primeWallet
        .getEpoch()
        .then((epoch) => {
          setTotalRewards(epoch?.totalRewards ?? 0);
        })
        .catch((err) => console.log(`Error occurred wile getting epoch: ${err}`));

      const api = new BabelFishApi("https://testnet.af.route3.dev/babelfish");
      api
        .getStakeAddressInfo(primeWallet.chain.name, primeWallet.getStakingWallet().address)
        .then((e) => {
          console.log(e.delegations);
          const allDelegations = e.delegations;
          const del = allDelegations
            .sort((a, b) => b.activeEpoch - a.activeEpoch)
            .map((e) => e.poolId)
            .map((e, i, final) => final.indexOf(e) === i && i)
            .filter((e) => allDelegations[e])
            .map((e) => allDelegations[e]);
          setDelegations(del);
        })
        .catch((err) => console.log(`Error occurred wile getting epoch: ${err}`));
    }
  }, [primeWallet]);

  return (
    <div className="staking-container">
      <div className={`staking-column gap-28 ${small && "staking-header-min"}`}>
        <span className="title">Stake</span>
        <div className="stake-rewards">
          <div className="stake-info-div">
            <span className="stake-label">Total rewords</span>
            <span className="stake-amount">{formatAda(totalRewards)} APEX</span>
          </div>
        </div>
        {!small && (
          <>
            {delegations && pools.length > 0 && (
              <div className="delegations-container">
                <span className="stake-label">Current Staking</span>
                <div className="delegation-pools">
                  {delegations.map((delegation) => {
                    const pool = pools.find((pool) => pool.id == delegation.poolId);

                    return (
                      <div key={pool.id} className="pool-border">
                        <div className="pool-header">
                          <div className="pool-title">
                            <span className="pool-name">{pool?.name ?? "No name pool"}</span>
                            <div className="pool-id">
                              <span className="font-16">{shortId(pool?.id)}</span>
                              <CopyBtn {...{ data: pool.id }} />
                            </div>
                          </div>
                        </div>
                        <div className="pool-content">
                          <PoolRow label="Delegation Epoch" value={delegation.activeEpoch} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            <div className="staking-filter">
              <div className="stake-filter-header">
                <span className="subtitle">Active Pools</span>
                {/* <button>Sort</button> */}
              </div>
              <PoolFilter {...filterProps} />
            </div>
          </>
        )}
      </div>

      <div className="pool-container">
        {loading ? (
          <Spinner />
        ) : (
          <>
            {pools.length === 0 ? (
              <span className="empty-pool-container">No pools for staking</span>
            ) : (
              <>
                {filteredPools.map((pool) => {
                  return (
                    <div key={pool.id} className="pool-border">
                      <div className="pool-header">
                        <div className="pool-title">
                          <span className="pool-name subtitle">{pool.name ?? "No name pool"}</span>
                          <div className="pool-id ">
                            <span className="small-text ">{shortId(pool.id)}</span>
                            <CopyBtn {...{ data: pool.id }} />
                          </div>
                          <SocialNetwork
                            {...{
                              name: pool.name,
                              ticker: pool.ticker,
                              description: pool.description,
                              homepage: pool.homepage,
                            }}
                          />
                        </div>
                      </div>

                      <div className="pool-content">
                        <PoolRow
                          label="Pool Size"
                          value={`${formatAda(pool.totalStaked)} APEX (${Number(pool.saturation * 100).toFixed(2)})%`}
                        />

                        <PoolRow label="Pledge" value={`${formatAda(pool.pledge)} APEX`} />

                        <PoolRow
                          label="Fee"
                          value={`${pool.margin * 100}% / ${formatAda(pool.fixedCost)}`}
                        />

                        <Button
                          label="Delegate"
                          color="outline"
                          action={() => navigate(`/stake/${pool.id}`)}
                        />
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
