export const getUniqueAssetsList = (assets) => {
  const uniqueAssets = Array.from(
    assets
      .reduce((map, asset) => {
        if (!map.has(asset.name)) {
          map.set(asset.name, { ...asset, count: 1 });
        } else {
          map.get(asset.name).amount += asset.amount;
          map.get(asset.name).count++;
        }
        return map;
      }, new Map())
      .values()
  );

  return uniqueAssets;
};
