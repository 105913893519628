import { Button, useInput } from "../../../components";
import { Address } from "@emurgo/cardano-serialization-lib-browser";
import { isAddress } from "ethers";

export const AddressInput = (props) => {
  const { prepareSubmit, token } = props;
  const errorMsg = `You are trying to send ${token.name} tokens from your ${token.network} address you need to enter a valid address that is also on ${token.network}.`;

  const [address, addressInput, validAddress] = useInput({
    label: "To",
    placeholder: "Enter address or search",
    pasteBtn: true,
    error: errorMsg,
    validation: () => addressValidation(),
  });

  const addressValidation = () => {
    let valid = true;
    if (token.network === "vector" || token.network === "prime") {
      try {
        Address.from_bech32(address);
      } catch (error) {
        valid = false;
        console.log("error: ", error);
      }
    } else {
      valid = isAddress(address);
    }
    return valid;
  };

  return (
    <>
      {addressInput}
      {address && (
        <Button
          label="Next"
          disabled={!validAddress}
          action={() => {
            console.log(address);
            prepareSubmit({ address });
          }}
        />
      )}
    </>
  );
};
