import Website from "./../../assets/website.svg";
import X from "./../../assets/xIcon.svg";
import Telegram from "./../../assets/telegram.svg";
import Youtube from "./../../assets/youtube.svg";
import Discord from "./../../assets/discord.svg";

export const SocialNetwork = ({ meta }) => {
  return (
    <div className="pool-links pool-padding-10">
      {meta?.homepage && <SocialIcon {...{ social: "homepage", link: meta.homepage }} />}
      {meta?.x && <SocialIcon {...{ social: "x", link: meta.x }} />}
      {meta?.youtube && <SocialIcon {...{ social: "youtube", link: meta.youtube }} />}
      {meta?.discord && <SocialIcon {...{ social: "discord", link: meta.discord }} />}
      {meta?.telegram && <SocialIcon {...{ social: "telegram", link: meta.telegram }} />}
    </div>
  );
};

const SocialIcon = ({ social, link }) => {
  const socialIcon = {
    homepage: <Website />,
    x: <X />,
    youtube: <Youtube />,
    discord: <Discord />,
    telegram: <Telegram />,
  };
  return (
    <a href={link} target="_blank">
      {socialIcon[social]}
    </a>
  );
};
