export const Receive = ({ width = 53, height = 55, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 52 55"
      fill="none"
      transform="matrix(1,0,0,-1,0,0)"
    >
      <path
        d="M26 36C26 36.5523 26.4477 37 27 37C27.5523 37 28 36.5523 28 36L26 36ZM27.7071 19.2929C27.3166 18.9024 26.6834 18.9024 26.2929 19.2929L19.9289 25.6569C19.5384 26.0474 19.5384 26.6805 19.9289 27.0711C20.3195 27.4616 20.9526 27.4616 21.3431 27.0711L27 21.4142L32.6569 27.0711C33.0474 27.4616 33.6805 27.4616 34.0711 27.0711C34.4616 26.6805 34.4616 26.0474 34.0711 25.6569L27.7071 19.2929ZM28 36L28 20L26 20L26 36L28 36Z"
        fill={color ?? "#F7F7F7"}
      ></path>

      <rect x="20" y="13" width="14" height="1.2" rx="0.768116" fill={color ?? "#DEE5DF"} />
      <path d="M14.314 1H1V14.314" stroke={color ?? "#DEE5DF"} strokeWidth="0.768116"></path>
      <path
        d="M0.999999 40.686L1 54L14.314 54"
        stroke={color ?? "#DEE5DF"}
        strokeWidth="0.768116"
      ></path>
      <path
        d="M37.9985 54L51.3125 54L51.3125 40.686"
        stroke={color ?? "#DEE5DF"}
        strokeWidth="0.768116"
      ></path>
      <path
        d="M51.3125 14.314L51.3125 1L37.9985 0.999999"
        stroke={color ?? "#DEE5DF"}
        strokeWidth="0.768116"
      ></path>
    </svg>
  );
};
