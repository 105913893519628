import "./styles.css";
import { parseUri } from "@walletconnect/utils";
import { useContext } from "react";
import ReactQrReader from "react-qr-reader-es6";
import { WalletConnectContext } from "../../contexts/walletConnectContext";
import { useNavigate } from "react-router-dom";

export const WalletConnectQrReaderPage = () => {
  const navigate = useNavigate();

  const { web3wallet } = useContext(WalletConnectContext);
  // const [urii, setUrii] = useState("");

  async function onConnect(uri) {
    if (!uri) {
      return;
    }

    const { topic: pairingTopic } = parseUri(uri);
    // if for some reason, the proposal is not received, we need to close the modal when the pairing expires (5mins)
    const pairingExpiredListener = ({ topic }) => {
      if (pairingTopic === topic) {
        console.error("Pairing expired. Please try again with new Connection URI", "error");
        web3wallet.core.pairing.events.removeListener("pairing_expire", pairingExpiredListener);
      }
    };

    // web3wallet.once("session_proposal", () => {
    //   web3wallet.core.pairing.events.removeListener("pairing_expire", pairingExpiredListener);
    // });

    try {
      web3wallet.core.pairing.events.on("pairing_expire", pairingExpiredListener);
      console.log("pairing with uri", uri);
      await web3wallet.pair({ uri });
      console.log("paired with uri");
    } catch (error) {
      console.error("Error pairing wallet", error.message);
    } finally {
      navigate("/dashboard");
    }
  }

  return (
    <>
      <div className="qr-video-container">
        <ReactQrReader
          onError={console.error}
          onScan={onConnect}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        />
      </div>
      {/* <div>
        <input onChange={(e) => setUrii(e.target.value)} />
        <button onClick={() => onConnect(urii)}>Connect</button>
      </div> */}
    </>
  );
};
