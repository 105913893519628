import { Back, Button, Drawer, useInput } from "../../components";
import { useEffect } from "react";
import { tryDecryptMnemonic } from "../../services/blockchain/mnemonic";

export const LoginDrawer = ({ isOpen, toggleDrawer, action }) => {
  const [password, passwordInput, validPassword, setValidPassword] = useInput({
    placeholder: "Enter password",
    hideText: true,
    error: "Invalid password",
  });

  const confirm = async () => {
    const encryptedMnemonicData = JSON.parse(window.localStorage.getItem("encryptedMnemonicData"));
    tryDecryptMnemonic(
      encryptedMnemonicData.encryptedMnemonicHex,
      encryptedMnemonicData.saltHex,
      encryptedMnemonicData.ivHex,
      password
    )
      .then((mnemonic) => {
        action(mnemonic);
      })
      .catch(() => setValidPassword(false));
  };

  useEffect(() => {
    if (!validPassword) setValidPassword(true);
  }, [password]);

  return (
    <Drawer {...{ isOpen, toggleDrawer }}>
      <div className="drawer-header">
        <div className="drawer-padding-top-3">
          <Back action={() => toggleDrawer((val) => !val)} />
        </div>

        <span className="subtitle">Enter your password to approve the transaction</span>
      </div>
      <>{passwordInput}</>

      <Button label="Confirm" disabled={password === ""} action={confirm} />
    </Drawer>
  );
};
