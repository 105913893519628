import "./spinner.css";

export const Spinner = () => {
  return (
    <div className="loader spin-primary">
      <div className="spin__bottom-left-primary" />
      <div className="spin__bottom-right-primary" />
      <div className="spin__top-left-primary" />
      <div className="spin__top-right-primary" />
    </div>
  );
};
