import "./dashboard.css";
import NexusIcon from "./../../assets/nexus.svg";
import VectorIcon from "./../../assets/vector.svg";
import PrimeIcon from "./../../assets/prime.svg";
import { formatAmount } from "../../utils/formatAmount";
import { useEffect, useState } from "react";
import UnknownTokenIcon from "./../../assets/unknownToken.svg";
import { useNavigate } from "react-router-dom";
import { getUniqueAssetsList } from "../../utils/getUniqueAssetsList";

const tabs = [
  { id: 1, value: "all", label: "All assets" },
  { id: 2, value: "prime", label: <PrimeIcon /> },
  { id: 3, value: "nexus", label: <NexusIcon /> },
  { id: 4, value: "vector", label: <VectorIcon /> },
];

export const Tabs = ({ list = [] }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("all");
  const [assetList, setAssetList] = useState(list);

  useEffect(() => {
    setAssetList(activeTab === "all" ? getUniqueAssetsList(list) : list);
  }, [list]);

  const changeTab = (value) => {
    setActiveTab(value);

    const filteredList =
      value !== "all" ? list.filter((el) => el.network === value) : getUniqueAssetsList(list);
    setAssetList(filteredList);
  };

  const listTransactions = ({ name = "all" }) => {
    navigate({
      pathname: "/list-transactions",
      search: `?asset=${name}`,
    });
  };

  return (
    <div className="tabs-container">
      <div className="tabs">
        {tabs.map((tab) => {
          const { id, value, label } = tab;
          return (
            <div
              key={id}
              className={`tab ${activeTab === value && "tab-active"}`}
              onClick={() => changeTab(value)}
            >
              {label}
            </div>
          );
        })}
      </div>
      <div className="assets">
        {assetList.map((asset) => {
          const { id, icon, name, amount } = asset;
          return (
            <button key={id} className="asset" onClick={() => listTransactions({ name })}>
              <div className="asset">
                {icon ? (
                  <img src={icon} alt="" className="asset-icon" />
                ) : (
                  <UnknownTokenIcon className="asset-icon" />
                )}
                <div className="asset-name">
                  <div>{name}</div>
                </div>
              </div>
              <div className="asset-value">
                <div>
                  {formatAmount(amount)} {name}
                </div>
              </div>
            </button>
          );
        })}
        {assetList.length === 0 && <div className="no-assets">No assets for this network</div>}
      </div>
    </div>
  );
};
