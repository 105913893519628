import "./navbar.css";
import { Outlet, useNavigate } from "react-router-dom";
import AssetsIcon from "./../../assets/assetsIcon.svg";
import TransactionsIcon from "./../../assets/transactionsIcon.svg";
import SettingsIcon from "./../../assets/settingsIcon.svg";

export const Navbar = () => {
  const navigate = useNavigate();

  return (
    <>
      <Outlet />
      <div className="layout">
        <button className="layout-item" onClick={() => navigate("/dashboard")}>
          <AssetsIcon />
          Assets
        </button>
        <button className="layout-item" onClick={() => navigate("/list-transactions")}>
          <TransactionsIcon />
          Transactions
        </button>
        <button className="layout-item" onClick={() => navigate("/settings")}>
          <SettingsIcon />
          Settings
        </button>
      </div>
    </>
  );
};
