import "./staking.css";
import { useContext, useEffect, useState } from "react";
import { Button, CopyBtn, NavHeader, NetworkIcon } from "../../components";
import { useNavigate, useParams } from "react-router";
import { InfoDrawer } from "./infoDrawer";
import { PoolRow } from "./poolRow";
import { formatAda } from "../../utils/formatAda";
import Stake from "./../../assets/stake.svg";
import { shortId } from "../../utils/shortId";
import { SocialNetwork } from "./socialNetwork";
import { NetworkContext } from "../../contexts/networkContext";

export const PoolDetailsPage = () => {
  const poolId = useParams().id;
  const navigate = useNavigate();
  const [pool, setPool] = useState({});
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [infoType, setInfoType] = useState("");
  const { primeWallet, submitDelegation, addPendingTransaction } = useContext(NetworkContext);

  useEffect(() => {
    primeWallet
      .getPool(poolId)
      .then((pool) => {
        setPool(pool);
        setLoading(false);
      })
      .catch((err) =>
        console.log(`Error occurred wile getting staking pool with id: ${poolId}: ${err}`)
      );
  }, [primeWallet]);

  const delegationAction = async () => {
    submitDelegation(poolId)
      .then((tx) => {
        const transaction = {
          token: "",
          network: "prime",
          address: "",
        };
        addPendingTransaction(tx.hash, transaction, 0);
        navigate("/list-transactions");
      })
      .catch((err) => {
        console.log(err);
        navigate("/error");
      });
  };

  const infoProp = (type) => {
    setIsInfoOpen((val) => !val);
    setInfoType(type);
  };

  return (
    <div className="pool-details-container">
      <div className="pool-details-header">
        <div className="pool-details-nav">
          <NavHeader {...{ label: "Confirm Staking" }} />
        </div>
        <Stake />

        <div className="pool-details-title">
          <div>
            Confirm staking to <br />
            <div className="pool-details-title">
              {/* <TestIcon /> */}
              <span className="pool-name">{pool.name ?? "No name pool"}</span>
            </div>
            {/* <div className="pool-links">
              <Website />
              <X />
              <Youtube />
              <Discord />
              <Telegram />
            </div> */}
            <SocialNetwork
              {...{
                name: pool.name,
                ticker: pool.ticker,
                description: pool.description,
                homepage: pool.homepage,
              }}
            />
          </div>
        </div>
      </div>
      <div className="pool-details-content">
        <PoolRow label="Transaction type" value="Staking" />
        <PoolRow label="Network" value={<NetworkIcon {...{ network: "prime" }} />} />

        <PoolRow
          label="Pool ID"
          value={
            <div className="pool-id">
              <span className="font-16">{shortId(pool.id, true)}</span>
              <CopyBtn {...{ data: pool.id }} />
            </div>
          }
        />

        <div className="divider" />

        <PoolRow
          label="Pool Size"
          value={`${formatAda(pool.totalStaked)} APEX (${Number(pool.saturation * 100).toFixed(2)})%`}
          action={() => infoProp("saturation")}
        />

        <PoolRow
          label="Pledge"
          value={`${formatAda(pool.pledge)} APEX`}
          action={() => infoProp("pledge")}
        />

        <div className="divider" />

        <PoolRow
          label="Fee"
          value={`${pool.margin * 100}% / ${formatAda(pool.fixedCost)}`}
          action={() => infoProp("margin")}
        />
      </div>

      <Button label="Delegate" color="outline" action={delegationAction} />
      <InfoDrawer isOpen={isInfoOpen} toggleDrawer={setIsInfoOpen} type={infoType} />
    </div>
  );
};
