import { Back, Button, Drawer } from "../../components";
import { NavHeader } from "../../components/navHeader/navHeader";

export const InfoDrawer = ({ isOpen, toggleDrawer, type = "margin" }) => {
  return (
    <Drawer {...{ isOpen, toggleDrawer }}>
      <NavHeader {...{ label: type, action: () => toggleDrawer((val) => !val) }} />
      {type === "margin" && (
        <>
          <span>
            The Margin is the cut the Pool Operator takes from the rewards before distributing them
            among its delegators.
          </span>
          <span className="small-text">
            For example - The Stake pool gets 1340 APEX rewards for its minted blocks in a given
            Epoch. Pool operator gets 340 APEX (Epoch Fee), and then there's 1000 APEX left. From
            this 1000 APEX pool takes a 1% Margin (10 APEX), which leaves delegators 990 ADA. This
            will be distributed among all delegators according to their stake size.
          </span>
        </>
      )}

      {type === "saturation" && (
        <span>
          Pool saturation is the limit to the pool size, after which the pool rewards will be
          capped. The saturation limit of a pool is about 64M ADA as of now. When the limit is
          reached, the pool will no longer be getting higher rewards for more delegations.
        </span>
      )}

      {type === "pledge" && (
        <span>Pledge is the amount of APEX the pool operator has staked in their own pool.</span>
      )}

      <Button label="Learn more about Staking" color="outline" />
    </Drawer>
  );
};
