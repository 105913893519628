import "./receive.css";
import "./../transactions.css";
import QRIcon from "./../../../assets/qrIcon.svg";
import { shortAddress } from "./../../../utils/shortAddress";
import { useContext, useState } from "react";
import { ExportDrawer } from "./receiveDrawer";
import { NetworkContext } from "../../../contexts/networkContext";
import { CopyBtn } from "../../../components/copyBtn/copyBtn";
import { NetworkIcon } from "../../../components/networkIcon";
import { NavHeader } from "../../../components/navHeader/navHeader";

export const ReceivePage = () => {
  const { receivingAddresses } = useContext(NetworkContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [addressOpen, setAddressOpen] = useState("");
  const [networkOpen, setNetworkOpen] = useState("");

  return (
    <div className="transaction-container">
      <NavHeader {...{ label: "Receive Crypto" }} />

      <div className="receive-list">
        {receivingAddresses.map((el) => {
          const { id, network, description, address } = el;
          return (
            <div key={id} className="receive-network">
              <div className="receive-info">
                <NetworkIcon {...{ network }} />
                <div className="receive-description">{description}</div>
                <div className="receive-address">{shortAddress(address)}</div>
              </div>
              <div className="receive-actions">
                <button
                  className="receive-btn"
                  onClick={() => {
                    setDrawerOpen((val) => !val);
                    setAddressOpen(address);
                    setNetworkOpen(network);
                  }}
                >
                  <QRIcon />
                </button>

                <div className="receive-btn">
                  <CopyBtn {...{ data: address }} />
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <ExportDrawer
        isOpen={drawerOpen}
        toggleDrawer={setDrawerOpen}
        address={addressOpen}
        network={networkOpen}
      />
    </div>
  );
};
