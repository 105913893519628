import { JsonRpcProvider, parseUnits } from "ethers";
import { formatEther, formatUnits } from "ethers";

export class EthereumQuerier {
  #address;
  #provider;
  #indexerUrl;

  constructor(address, jsonRpcUrl, indexerUrl) {
    this.#address = address;
    this.#provider = new JsonRpcProvider(jsonRpcUrl);
    this.#indexerUrl = indexerUrl;
  }
  //Get balance of assets
  async getBalance() {
    const resp = await fetch(
      `${this.#indexerUrl}?module=account&action=eth_get_balance&address=${this.#address}`
    );
    const data = await resp.json();
    if (!resp.ok) {
      // TODO: handle error
      if (resp.status !== 404) {
        console.error("Could not fetch Ethereum balance");
      }
      return 0n;
    }

    const hexBalance = data.result;
    return formatEther(hexBalance);
  }

  async getPendingTransactions() {
    const resp = await fetch(
      `${this.#indexerUrl}?module=account&action=pendingtxlist&address=${this.#address}`
    );
    const data = await resp.json();
    if (!resp.ok) {
      // TODO: handle error
      if (resp.status !== 404) {
        console.error("Could not fetch pending Ethereum transactions");
      }
      return [];
    }

    return data.result;
  }

  // Returns a list of all transactions
  async getTransactions() {
    const resp = await fetch(
      `${this.#indexerUrl}?module=account&action=txlist&address=${this.#address}`
    );
    const data = await resp.json();
    if (!resp.ok) {
      // TODO: handle error
      if (resp.status !== 404) {
        console.error("Could not fetch Ethereum transactions");
      }
      return [];
    }

    const txs = data.result;
    const transfers = [];
    for (const tx of txs) {
      if ((!tx.input || tx.input === "0x") && !tx.contractAddress) {
        // this is a coin transfer
        transfers.push({
          from: tx.from,
          to: tx.to,
          amount: formatEther(tx.value),
          fees: formatEther(BigInt(tx.gasUsed) * BigInt(tx.gasPrice)),
          timestamp: new Date(Number(tx.timeStamp) * 1000),
          type: Number(this.#address) === Number(tx.from) ? "send" : "receive",
          hash: tx.hash,
        });
      }
    }

    return transfers;
  }

  //Get balance of all tokens in wallet
  async getTokens() {
    const resp = await fetch(
      `${this.#indexerUrl}?module=account&action=tokenlist&address=${this.#address}`
    );

    const data = await resp.json();
    if (!resp.ok) {
      // TODO: handle error
      if (resp.status !== 404) {
        console.error("Could not fetch Ethereum tokens");
      }
      return [];
    }

    const tokens = data.result;
    for (const token of tokens) {
      token.balance = formatUnits(token.balance, Number(token.decimals));
    }

    return data.result;
  }

  async estimateSendCoinGasCost(to, value) {
    const gas = await this.#provider.estimateGas({
      from: this.#address,
      to: to,
      data: "0x",
      value: parseUnits(value, "ether"),
    });
    const feeData = await this.#provider.getFeeData();

    if (feeData.gasPrice) {
      let gasCost = Number(gas * feeData.gasPrice) / 1000000000000000000;
      return gasCost;
    }

    return 0n;
  }

  // Get wallet address
  getAddress() {
    return this.#address;
  }
}
