// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.area-container {
  border: 1px solid #5c5c5e;
  background: var(--background);
  color: var(--text);

  display: flex;
  flex-direction: row;

  padding: 12px 10px 19px;
}

.area-img {
  align-items: center;
  display: flex;

  background: transparent;
  border: none;
}

.area-hidden {
  filter: blur(10px);
}
`, "",{"version":3,"sources":["webpack://./src/pages/onboarding/textArea/textArea.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,6BAA6B;EAC7B,kBAAkB;;EAElB,aAAa;EACb,mBAAmB;;EAEnB,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;EACnB,aAAa;;EAEb,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".area-container {\n  border: 1px solid #5c5c5e;\n  background: var(--background);\n  color: var(--text);\n\n  display: flex;\n  flex-direction: row;\n\n  padding: 12px 10px 19px;\n}\n\n.area-img {\n  align-items: center;\n  display: flex;\n\n  background: transparent;\n  border: none;\n}\n\n.area-hidden {\n  filter: blur(10px);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
