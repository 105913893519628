import { Outlet } from "react-router-dom";
import { WalletConnectProvider } from "../contexts/walletConnectContext";

export const WalletConnectLayout = () => {
  return (
    <WalletConnectProvider>
      <Outlet />
    </WalletConnectProvider>
  );
};
