import { Wallet, JsonRpcProvider, parseUnits } from "ethers";

export class EthereumTransactor {
  #wallet;

  /**
   * @param {string} mnemonic
   * @param {string} jsonRpcUrl
   */
  constructor(mnemonic, jsonRpcUrl) {
    const provider = new JsonRpcProvider(jsonRpcUrl);
    this.#wallet = Wallet.fromPhrase(mnemonic, provider);
  }

  /**
   * @param {string} to
   * @param {string} value
   */
  async sendCoin(to, value) {
    return this.#wallet.sendTransaction({
      from: this.#wallet.address,
      to: to,
      data: "0x",
      value: parseUnits(value, "ether"),
    });
  }

  /**
   * @param {{ from: string, to: string, data: string, value: string }} tx
   */
  async sendTransaction(tx) {
    // test tx before sending
    const gas = await this.#wallet.estimateGas({
      from: tx.from,
      to: tx.to,
      data: tx.data,
      value: tx.value,
    });
    const resp = await this.#wallet.sendTransaction({
      from: tx.from,
      to: tx.to,
      data: tx.data,
      value: tx.value,
      gasLimit: gas,
    });
    return resp;
  }

  /**
   * @param {string} msg
   */
  async signMessage(msg) {
    await this.#wallet.signMessage(msg);
  }

  /**
   * @returns {string}
   */
  getAddress() {
    return this.#wallet.address;
  }
}
