import { useEffect, useState } from "react";
import { Button, Checkbox, useInput } from "../../components";

export const Password = ({ onContinue, setPassword }) => {
  const [agreed, setAgreed] = useState(false);
  const [password, passwordInput, validPassword] = useInput({
    placeholder: "Enter password",
    hideText: true,
    error:
      "The password needs to be 8 characters long and contain at least 1 lowercase character, 1 uppercase character, 1 number and 1 special character",
    validation: () => validatePassword(),
  });

  const [repeatPassword, repeatPasswordInput, validRepeatPassword, setRepeatPasswordValidity] =
    useInput({
      placeholder: "Enter password",
      hideText: true,
      error: "The password needs to match",
      validation: () => validateRepeatPassword(),
    });

  const validatePassword = () => {
    let regex = /^(?=.*\d)(?=.*[!@#$%^&*-_])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    let result = regex.test(password);

    return result;
  };

  const validateRepeatPassword = () => {
    return password === repeatPassword;
  };

  useEffect(() => {
    if (password !== repeatPassword && repeatPassword !== "") setRepeatPasswordValidity(false);
  }, [password]);

  return (
    <>
      <div className="content">
        <div className="title">Create Password </div>
        <div>
          Please set a password for your cloud backup and save it somewhere secure. We can't reset
          the password for you. Do not reuse your cloud password.
        </div>
        <div className="password-input">{passwordInput}</div>
        {validPassword && password !== "" && (
          <div className="password-input">{repeatPasswordInput}</div>
        )}

        <div className="password-agreement">
          <Checkbox
            value={"agreement"}
            checked={agreed}
            onChange={() => setAgreed((val) => !val)}
          />

          <span className="agreement-text">
            I understand that if I lose my password, I will not be able to access my recovery
            phrase, resulting in the loss of all the crypto in my wallet.
          </span>
        </div>
      </div>

      <Button
        label="Confirm"
        disabled={!(validPassword && repeatPassword !== "" && validRepeatPassword && agreed)}
        action={() => {
          setPassword(password);
          onContinue();
        }}
      />
    </>
  );
};
