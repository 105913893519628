import { useContext, useEffect, useState } from "react";
import { Button, Drawer, Spinner } from "../../../components";
import { useNavigate } from "react-router-dom";
import { formatAmount } from "../../../utils/formatAmount";
import { NetworkContext } from "../../../contexts/networkContext";
import { MaxAmount } from "./maxAmount/maxAmount";
import { NavHeader } from "../../../components/navHeader/navHeader";

export const AmountPage = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [disclaimer, setDisclaimer] = useState(false);
  const [transaction] = useState(JSON.parse(window.localStorage.getItem("transaction")));
  const [token] = useState(transaction.token);
  const [amount, setAmount] = useState(
    window.localStorage.getItem("amount") ? Number(window.localStorage.getItem("amount")) : 0
  );
  const [maxSelected, setMaxSelected] = useState(false);
  const [amountChanged, setAmountChanged] = useState(amount !== 0);
  const { fee, estimateFee, loading } = useContext(NetworkContext);

  const calculate = (num) => {
    setAmountChanged(true);
    let amount = Math.abs(num);

    if (maxSelected) setMaxSelected(false);
    setAmount(amount);
    window.localStorage.setItem("amount", amount);
  };

  const calculateMax = () => {
    setAmountChanged(true);
    setMaxSelected(true);
    estimateFee(transaction?.network, transaction.address, token.hash, token.balance - 2);
  };

  useEffect(() => {
    const roundUp = (number) => {
      return Math.round(Number(number) * 1000000) / 1000000;
    };

    if (fee !== undefined && fee !== "" && maxSelected) {
      const feeRoundUp = fee ? roundUp(fee) : 0;

      const val = token.token === "APEX" ? token.balance - feeRoundUp - 2 : token.balance;
      calculate(roundUp(val));
    }
  }, [fee]);

  useEffect(() => {
    const isValidAmount = () => {
      const tmpAmount = token.token === "APEX" ? amount : amount * 1000000;
      let invalidInput =
        tmpAmount === 0 ||
        amount > token.balance ||
        ((transaction.network === "vector" || transaction.network === "prime") && tmpAmount < 1);

      setError(invalidInput);
    };

    isValidAmount();
  }, [amount]);

  return (
    <div className="transaction-container">
      {loading && <Spinner />}

      <NavHeader {...{ label: "Amount" }} />

      <div className="transaction-selection horizontal-center">
        <div className="amount-top-row">
          <div />
          <div className="amount-token">
            {token.icon ? (
              <img src={token.icon} alt="" className="amount-token-icon" />
            ) : (
              <UnknownToken />
            )}
            {token.token}
          </div>
          <button className="small-text" onClick={() => setDisclaimer(true)}>
            MAX
          </button>
        </div>

        <input
          className="amount-value"
          type="number"
          value={amount}
          onInput={(e) => calculate(e.target.value)}
        />
        {error && amountChanged && <div className="error">Not enough {token.token}</div>}

        <div>
          Balance: {formatAmount(token.balance)}
          <span className="uppercase"> {token.token} </span>
        </div>
      </div>
      <Button label="Next" action={() => navigate("/transaction/confirm")} disabled={error} />

      <DisclaimerDrawer
        isOpen={disclaimer}
        toggleDrawer={setDisclaimer}
        submit={calculateMax}
        token={token.token}
      />
    </div>
  );
};

const DisclaimerDrawer = ({ isOpen, toggleDrawer, submit, token }) => {
  return (
    <Drawer {...{ isOpen, toggleDrawer }}>
      <NavHeader {...{ label: <MaxAmount big />, action: () => toggleDrawer((val) => !val) }} />
      <div className="subtitle">We’ve saved enough {token} to cover the gas fee</div>
      Sending {token} requires an additional network fee also paid in {token}. We've deducted the
      fee amount from the amount you entered to ensure your transaction can be submitted. If network
      fees change after you continue, the amounts will update in real-time to reflect the changes.
      <Button
        label="Confirm"
        action={() => {
          toggleDrawer((val) => !val);
          submit();
        }}
      />
    </Drawer>
  );
};
