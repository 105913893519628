import "./../transactions.css";
import { Spinner } from "../../../components";
import { TokenList } from "./tokenList";
import { useContext, useEffect, useState } from "react";
import { NetworkList } from "./networkList";
import { useNavigate } from "react-router-dom";
import { NetworkContext } from "../../../contexts/networkContext";
import { AddressInput } from "./address";
import { useStateCallback } from "../../../utils/useStateCallback";
import { NavHeader } from "../../../components/navHeader/navHeader";
import { getUniqueAssetsList } from "../../../utils/getUniqueAssetsList";

let availableNetworks = [
  { id: 2, name: "prime", token: null },
  { id: 1, name: "nexus", token: null },
  { id: 3, name: "vector", token: null },
];

export const NewTransactionPage = () => {
  const navigate = useNavigate();
  const { assets, loading } = useContext(NetworkContext);
  const [tokenList, setTokenList] = useStateCallback([]);
  const [networkList, setNetworkList] = useState(availableNetworks);
  const [networkListForToken, setNetworkListForToken] = useState(availableNetworks);

  const [token, setToken] = useState(null);
  const [network, setNetwork] = useState(null);
  const [address, setAddress] = useState(false);

  const HeaderLabel = () => {
    if (token && !network) return <span>Select network</span>;
    if (network) return <span>Enter address</span>;
    return <span>Select token to send</span>;
  };

  useEffect(() => {
    const uniqueAssets = getUniqueAssetsList(assets);
    setTokenList(uniqueAssets);
  }, [assets]);

  const mapNetworksForToken = (tokenVal) => {
    return availableNetworks.map((network) => ({
      id: network.id,
      name: network.name,
      amount:
        assets.find((asset) => asset.name === tokenVal.name && asset.network === network.name)
          ?.amount ?? 0,
      token: tokenVal?.name,
    }));
  };

  const setTokenValue = (value, setStorage = true) => {
    setToken(value);
    setTokenList([value]);

    let tmp = mapNetworksForToken(value);
    setNetworkList(tmp);
    setNetworkListForToken(tmp);

    if (setStorage) {
      let storageToken = {
        token: value,
        balance: Number(assets.find((token) => token.name === value.name).amount ?? 0),
        icon: value.icon,
      };

      setLocalStorage({ token: storageToken });
    }
  };

  const setNetworkValue = (value, setStorage = true) => {
    setNetwork(value);
    setNetworkList([networkList.find((network) => network.name === value)]);

    const tokenName = tokenList[0].name;
    let token = assets.find((token) => token.name === tokenName && token.network === value);

    setToken(token);
    setAddress(true);
    if (setStorage) {
      let storageToken = {
        hash: token.hash,
        token: token.name,
        balance: Number(token.amount ?? 0),
        icon: token.icon,
      };

      setLocalStorage({ token: storageToken, network: value });
    }
  };

  const goBack = () => {
    if (address) {
      setNetwork("");
      setNetworkList(networkListForToken);
      setAddress(false);
    } else if (networkList.length === 1) {
      setNetwork("");
      setNetworkList(availableNetworks);
    } else if (tokenList.length === 1) {
      setToken("");
      setTokenList(getUniqueAssetsList(assets));
    } else {
      navigate("/dashboard");
    }
  };

  // adding address into local storage
  const prepareSubmit = ({ address }) => {
    setLocalStorage({ address: address });
    navigate("/transaction/amount");
  };

  return (
    <div className="transaction-container">
      <NavHeader {...{ label: <HeaderLabel />, action: goBack }} />
      {loading ? (
        <Spinner />
      ) : (
        <div className="transaction-selection">
          <TokenList {...{ tokenList, setToken: token ? () => {} : setTokenValue }} />
          {token && (
            <NetworkList
              {...{
                networkList,
                setNetwork: setNetworkValue,
              }}
            />
          )}
          {network && <AddressInput {...{ prepareSubmit, token }} />}
        </div>
      )}
    </div>
  );
};

const setLocalStorage = ({ token, network, address }) => {
  let tmp = JSON.parse(window.localStorage.getItem("transaction")) ?? {
    token: "",
    network: "",
    address: "",
  };

  if (token && network) tmp = { token, network };
  else if (token) tmp.token = token;
  else if (network) tmp.network = network;
  else if (address) tmp.address = address;
  else return;

  window.localStorage.setItem("transaction", JSON.stringify(tmp));
};
