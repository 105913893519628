export const PoolFilter = ({ list, setList }) => {
  const filterByValue = (array, string) => {
    // return array.filter((o) =>
    //   Object.keys(o).some((k) => o[k].toLowerCase().includes(string.toLowerCase()))
    // );
    // console.log("filterByValue", array);
    return array.filter(
      (data) => JSON.stringify(data).toLowerCase().indexOf(string.toLowerCase()) !== -1
    );
  };

  const search = (e) => {
    const filteredPools = filterByValue(list, e.target.value);
    setList(filteredPools);
  };
  return (
    <>
      <input className="filter-input" onInput={search} />
    </>
  );
};
