import "./styles.css";
import { Button } from "../../components";

export const SessionProposal = (props) => {
  const {
    dapp = {
      name: "",
      url: "",
      iconUrl: "",
    },
    approve = () => {},
    reject = () => {},
  } = props;

  return (
    <>
      <div className="connect-header">
        <div className="subtitle">Connect your account</div>
        <div className="app">
          {dapp.iconUrl && <img src={dapp.iconUrl} alt="" className="connect-icon" />}
          <span>{dapp.url.replace("https://", "")}</span>
        </div>
      </div>
      <div className="disclaimer">
        Share your account address, balance and history and allow the dapp to start a transaction
      </div>
      <div className="inline connect-actions">
        <Button label="Cancel" color="outline" action={reject} />
        <Button label="Connect" action={approve} />
      </div>
    </>
  );
};
