import PrimeIcon from "./../assets/prime.svg";
import NexusIcon from "./../assets/nexus.svg";
import VectorIcon from "./../assets/vector.svg";

export const NetworkIcon = ({ network }) => {
  const networkList = {
    prime: <PrimeIcon />,
    nexus: <NexusIcon />,
    vector: <VectorIcon />,
  };

  return networkList[network];
};
