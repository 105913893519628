import "./onboarding.css";
import { Backup } from "./backup";
import { Verification } from "./verification";
import { Apex } from "./apex";
import { Wallet } from "./wallet";
import { Back } from "../../components";
import { useContext, useEffect, useState } from "react";
import { NetworkContext } from "../../contexts/networkContext";
import { useNavigate } from "react-router-dom";
import { Password } from "./password";
import { encryptMnemonic, generateMnemonic } from "../../services/blockchain/mnemonic";
import { EthereumTransactor } from "../../services/blockchain/ethereum/wallet/transactor";
import { chainConfig } from "../../../config";

export const OnboardingPage = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (window.localStorage.getItem("onboarded")) navigate("/dashboard");
  }, []);

  const goBack = () => setStep((step) => step - 1);

  return (
    <div className="container">
      {step > 2 && (
        <div className="header">
          <Back {...{ action: goBack }} />
          <Progress {...{ step }} />
        </div>
      )}
      <PageContent {...{ step, setStep }} />
    </div>
  );
};

const Progress = ({ step }) => {
  return (
    <div className="progress">
      <div className={`progress-step ${step >= 3 && "progress-step-done"}`} />
      <div className={`progress-step ${step >= 4 && "progress-step-done"}`} />
      <div className={`progress-step ${step >= 5 && "progress-step-done"}`} />
    </div>
  );
};

const PageContent = ({ step, setStep }) => {
  const navigate = useNavigate();

  const { setWallets } = useContext(NetworkContext);
  const [mnemonic, setMnemonic] = useState("");
  const [password, setPassword] = useState("");

  const onContinue = async () => {
    if (step === 3) {
      setMnemonic(
        //   "soul dilemma cream detect heart cool super tree mean robust universe fabric drop plastic paper harvest hen ship hidden bag shoe number tilt warm"
        // );
        generateMnemonic()
      );
    } else if (step === 5) {
      const encryptedMnemonicData = await encryptMnemonic(mnemonic, password);
      window.localStorage.setItem("onboarded", true);
      window.localStorage.setItem("encryptedMnemonicData", JSON.stringify(encryptedMnemonicData));
      window.localStorage.setItem("mnemonic", mnemonic);

      const nexusTransactor = new EthereumTransactor(mnemonic, chainConfig.nexus.jsonRpcUrl);
      const nexusAddr = nexusTransactor.getAddress();

      const accounts = {
        nexus: {
          address: nexusAddr,
        },
      };
      window.localStorage.setItem("accounts", JSON.stringify(accounts));
      setWallets(mnemonic);

      navigate("/dashboard");
    }

    setStep((step) => step + 1);
  };

  switch (step) {
    case 1:
      return <Apex {...{ onContinue }} />;
    case 2:
      return <Wallet {...{ onContinue }} />;
    case 3:
      return <Password {...{ onContinue, setPassword }} />;
    case 4:
      return <Backup {...{ mnemonic, onContinue }} />;
    case 5:
      return <Verification {...{ mnemonic, onContinue }} />;
    default:
      return <Apex />;
  }
};
