import { Send } from "../../assets/send";
import "./loader.css";

export const Loader = () => {
  return (
    <div className="loader-container ">
      <Send filled={true} />
      <span className="subtitle">Sending your transaction</span>
      <span className="disclaimer">Just a moment</span>
    </div>
  );
};
