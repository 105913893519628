import { Receive } from "./../../../assets/receive";
import { Send } from "./../../../assets/send";
import { Stake } from "../../../assets/stake";

import NexusIcon from "./../../../assets/nexusRound.svg";
import VectorIcon from "./../../../assets/vectorRound.svg";
import PrimeIcon from "./../../../assets/primeRound.svg";

export const TransactionTypeIcon = ({ type, network }) => {
  const iconMap = {
    send: <Send height={36} width={34} color="#737979" />,
    receive: <Receive height={36} width={34} color="#077368" />,
    delegation: <Stake height={36} width={34} color="#737979" />,
    withdrawal: <Send height={36} width={34} color="#737979" />,
    internal: <Receive height={36} width={34} color="#077368" />,
  };

  return (
    <div className="transaction-icon">
      {iconMap[type]}
      <div className="network-icon">
        {network === "nexus" && <NexusIcon height={24} width={24} />}
        {network === "vector" && <VectorIcon height={24} width={24} />}
        {network === "prime" && <PrimeIcon height={24} width={24} />}
      </div>
    </div>
  );
};
