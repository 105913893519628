import { ApexLogo } from "../../assets/apex";
import { Button } from "../../components";

export const Apex = ({ onContinue }) => {
  return (
    <>
      <div className="logo-content">
        <div>
          <ApexLogo {...{ height: window.innerHeight / 2, width: window.innerWidth }} />
        </div>

        <div className="subtitle onboarding-subtitle word-break">
          {`Welcome to 
          Apex Fusion`}
        </div>
      </div>

      <Button
        label="Continue"
        action={() => {
          onContinue();
        }}
      />
    </>
  );
};
