import { shortAddress } from "../../../utils/shortAddress";
import { Button, Drawer } from "../../../components";
import { QrCodeGenerator } from "./qr/qrCodeGenerator";
import { CopyBtn } from "../../../components/copyBtn/copyBtn";

export const ExportDrawer = ({ isOpen, toggleDrawer, address, network }) => {
  return (
    <Drawer {...{ isOpen, toggleDrawer }}>
      <div className="center">
        <QrCodeGenerator {...{ address, network }} />
      </div>

      <div className="receive-drawer-address">
        <span className="text-18 grey">Your receiving address</span>
        <div className="receive-drawer-inline ">
          {shortAddress(address)}
          <CopyBtn {...{ data: address }} />
        </div>
      </div>
      <span className="hint grey">
        Use this address for receiving assets. Transactions may take a few minutes to complete.
      </span>
      <Button label="Share your address" color="white" />
    </Drawer>
  );
};
