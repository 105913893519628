import { createContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { EthereumTransactor } from "../services/blockchain/ethereum/wallet/transactor.js";
import { EthereumQuerier } from "../services/blockchain/ethereum/wallet/querier.js";
import { getId } from "./../utils/getId";
import { chainConfig } from "./../../config.js";
import apexLogo from "./../assets/apexIcon.png";
import { BabelFishApi, CardanoWalletBuilder, Chain, OgmiosApi } from "r3-apex-lib";

const NetworkContext = createContext();

const NetworkProvider = ({ children }) => {
  const navigate = useNavigate();

  const [nexusQuerier, setNexusQuerier] = useState();
  const [primeWallet, setPrimeWallet] = useState();
  const [vectorWallet, setVectorWallet] = useState();

  const [loading, setLoading] = useState(true);
  const [loadingHistory, setLoadingHistory] = useState(true);

  const [assets, setAssets] = useState([]);

  const [transactions, setTransactions] = useState([]);
  const [pendingTransactions, setPendingTransactions] = useState(
    JSON.parse(window.localStorage.getItem("pendingTransactions")) ?? []
  );

  const [receivingAddresses, setReceivingAddresses] = useState([]);

  const [fee, setFee] = useState();

  useEffect(() => {
    const onboarded = window.localStorage.getItem("onboarded");
    if (!onboarded) {
      return;
    }

    const mnemonic = window.localStorage.getItem("mnemonic");
    setWallets(mnemonic);
  }, []);

  useEffect(() => {
    if (!nexusQuerier || !primeWallet) {
      return;
    }

    setReceivingAddresses([
      {
        id: getId(),
        network: chainConfig.nexus.name,
        description: "Your Nexus receiving address",
        address: nexusQuerier.getAddress(),
      },
      {
        id: getId(),
        network: primeWallet.getChain().name,
        description: "Your Prime receiving address",
        address: primeWallet.getSpendingAddress(),
      },
      {
        id: getId(),
        network: vectorWallet.getChain().name,
        description: "Your Vector receiving address",
        address: vectorWallet.getSpendingAddress(),
      },
    ]);

    getAssets();
    getTransactions();
  }, [nexusQuerier, primeWallet]);

  const setWallets = (mnemonic) => {
    const accounts = JSON.parse(window.localStorage.getItem("accounts"));
    if (!accounts || !accounts.nexus) {
      throw new Error("failed to set queriers: accounts not found in localstorage");
    }

    setNexusQuerier(
      new EthereumQuerier(
        accounts.nexus.address,
        chainConfig.nexus.jsonRpcUrl,
        chainConfig.nexus.indexerUrl
      )
    );

    const prime = createCardanoWallet("prime", mnemonic);
    const vector = createCardanoWallet("vector", mnemonic);

    setPrimeWallet(prime);
    setVectorWallet(vector);

    accounts.prime = {
      receiveAddress: prime.getSpendingAddress(),
    };
    accounts.vector = {
      receiveAddress: vector.getSpendingAddress(),
    };

    window.localStorage.setItem("accounts", JSON.stringify(accounts));
  };

  const createCardanoWallet = (network, mnemonic) => {
    const indexer = new BabelFishApi("https://testnet.af.route3.dev/babelfish");
    const submitter = new OgmiosApi(`https://testnet.af.route3.dev/ogmios/${network}`);
    const mnemonicFunc = () => mnemonic;

    if (network === "prime") {
      const chain = new Chain(network, "testnet", 3311);
      return CardanoWalletBuilder.new(chain, indexer, submitter, mnemonicFunc).build();
    }

    if (network === "vector") {
      const chain = new Chain(network, 2, 1127);
      return CardanoWalletBuilder.new(chain, indexer, submitter, mnemonicFunc)
        .withCustomAddressPrefix("vector_test")
        .withoutStaking()
        .build();
    }
  };

  const stake = (poolId) => {
    primeWallet
      .getTransactionBuilder()
      .withDelegation(poolId)
      .build()
      .then((tx) => tx.submit());
  };

  const getAssets = () => {
    Promise.all([
      primeWallet.getBalance(),
      vectorWallet.getBalance(),
      nexusQuerier.getTokens(),
      nexusQuerier.getBalance(),
    ])
      .then((response) => {
        let mappedPrime = mapAssets(response[0], primeWallet.getChain().name);
        let mappedVector = mapAssets(response[1], vectorWallet.getChain().name);
        let mappedNexus = mapAssets(response[2], "nexus_token");
        let mappedNexusCoin = mapAssets(response[3], "nexus_balance");
        const assets = [...mappedPrime, ...mappedVector, ...mappedNexus, mappedNexusCoin];
        setAssets(assets);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
        Sentry.withScope((scope) => {
          scope.setFingerprint(["getAssets", String(err.statusCode)]);
          Sentry.captureException(err);
          navigate("/error");
        });
      });
  };

  const getTransactions = () => {
    Promise.all([
      primeWallet.getTransactions(),
      vectorWallet.getTransactions(),
      nexusQuerier.getTransactions(),
    ])
      .then((response) => {
        const trx = [
          ...mapTransactions(primeWallet.getChain().name, response[0]),
          ...mapTransactions(vectorWallet.getChain().name, response[1]),
          ...mapTransactions("nexus", response[2]),
        ];

        let ids = trx.map((el) => el.hash);
        let stillPendingTransactions = pendingTransactions.filter((el) => !ids.includes(el.hash));
        setPendingTransactions(stillPendingTransactions);

        setTransactions(
          [...trx, ...stillPendingTransactions].sort((a, b) => new Date(b.date) - new Date(a.date))
        );
        setLoadingHistory(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoadingHistory(false);
        Sentry.withScope((scope) => {
          scope.setFingerprint(["getTransactions", String(err.statusCode)]);
          Sentry.captureException(err);
          navigate("/error");
        });
      });
  };

  useEffect(() => {
    window.localStorage.setItem("pendingTransactions", JSON.stringify(pendingTransactions));
  }, [pendingTransactions]);

  const addPendingTransaction = (hash, transaction, amount) => {
    const { network, address, token } = transaction || {};

    let tmpTransaction = {
      hash: hash ?? 312,
      date: new Date(),
      type: "send",
      status: "pending",
      amount: Number(amount) ?? 0,
      network: network,
      fee: Number(fee) ?? 0,
      address: address,
      token: token?.token,
    };
    setPendingTransactions((prevPending) => [...prevPending, tmpTransaction]);
    setTransactions((prevTransactions) => [tmpTransaction, ...prevTransactions]);
  };

  const estimateFee = async (network, address, token, amount) => {
    setLoading(true);
    if (network === "prime" || network === "vector") {
      estimateCardanoTransactionFee(network, address, token, amount);
    } else {
      nexusQuerier
        .estimateSendCoinGasCost(address, amount.toString())
        .then((fee) => {
          setFee(fee);
          setLoading(false);
        })
        .catch((err) => {
          console.log("error: ", err);
          setLoading(false);
          Sentry.withScope((scope) => {
            scope.setFingerprint(["nexusEstimateFee", String(err.statusCode)]);
            Sentry.captureException(err);
            navigate("/error");
          });
        });
    }
  };

  const estimateCardanoTransactionFee = (network, address, token, amount) => {
    const wallet = network === "vector" ? vectorWallet : primeWallet;

    buildCardanoTransaction(network, address, token, amount)
      .then((tx) => {
        setFee(Number(tx.fee) / 1000000);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        Sentry.withScope((scope) => {
          scope.setFingerprint(["cardanoBuiltTx", String(err.statusCode)]);
          Sentry.captureException(err);
          navigate("/error");
        });
      });
  };

  const submitDelegation = (poolId) => {
    return primeWallet
      .getTransactionBuilder()
      .withDelegation(poolId)
      .build()
      .then((tx) => {
        return tx.submit();
      });
  };

  const buildCardanoTransaction = (network, address, token, amount) => {
    // if (token === "apex" || token === "lovelace") {
    amount *= 1000000;
    // }

    const wallet = network === "vector" ? vectorWallet : primeWallet;
    let builder = wallet.getTransactionBuilder();

    if (token === "apex" || token === "lovelace") {
      builder = builder.withSend(address, amount);
    } else {
      builder = builder.withAsset(address, token, amount);
    }

    return builder.build();
  };

  const submitCardanoTransaction = (network, address, token, amount) => {
    return buildCardanoTransaction(network, address, token, amount).then((tx) => {
      return tx.submit();
    });
  };

  const submitTransaction = (transaction, token, amount, mnemonic) => {
    if (transaction.network === "nexus") {
      const transactor = new EthereumTransactor(mnemonic, chainConfig.nexus.jsonRpcUrl);
      return transactor.sendCoin(transaction.address, amount.toString());
    } else if (transaction.network === "prime" || transaction.network === "vector") {
      return submitCardanoTransaction(transaction.network, transaction.address, token, amount);
    } else {
      throw new Error("received unsupported network in submitTransaction");
    }
  };

  const mapTransactions = (network, data) => {
    let transactions = [];
    if (network === "prime" || network === "vector") {
      transactions = data.map((tx) => mapCardanoTransaction(tx, network));
    } else if (network === "nexus") {
      transactions = data.map((trx) => ({
        hash: trx.hash,
        date: trx.timestamp,
        type: trx.type,
        status: "completed",
        amount: trx.amount,
        network: network,
        fee: trx.fees,
        to: trx.to,
        from: trx.from,
        token: "ETH",
      }));
    }
    return transactions;
  };

  const mapCardanoTransaction = (tx, network) => {
    const wallet = network === "vector" ? vectorWallet : primeWallet;
    const myAddress = wallet.getSpendingAddress();
    const amount = Number(tx.calculateAmount(myAddress)) / 1000000;
    const tokens = tx
      .calculateAssets(myAddress)
      .map((asset) =>
        asset.getName() === "lovelace" ? chainConfig[network].coinSymbol : asset.getName()
      );

    return {
      hash: tx.hash,
      date: tx.block.blockTime,
      type: tx.calculateType(myAddress),
      status: "completed",
      amount: amount,
      network: network,
      fee: Number(tx.fee) / 1000000,
      from: myAddress, // write helper function to find to/from
      tokens: tokens,
    };
  };

  const mapAssets = (res, type) => {
    let mappedAssets = [];

    if (type === primeWallet.getChain().name || type === vectorWallet.getChain().name) {
      mappedAssets = [...res].map((asset) => {
        const name =
          asset.getName() === "lovelace" ? chainConfig[type].coinSymbol : asset.getName();
        const isCoin = asset.getName() === "lovelace";
        return {
          id: getId(),
          name: name,
          hash: asset.hash,
          amount: Number(asset.amount) / 1000000,
          network: type,
          icon: apexLogo,
          isCoin: isCoin,
        };
      });
    } else if (type === "nexus_token") {
      mappedAssets = res.map((el) => ({
        id: getId(),
        name: el.symbol,
        amount: el.balance,
        network: "nexus",
        icon: "",
        isCoin: false,
      }));
    } else if (type === "nexus_balance") {
      mappedAssets = {
        id: getId(),
        // name: "ETH",
        name: chainConfig["nexus"].coinSymbol,
        amount: Number(res),
        network: "nexus",
        icon: apexLogo,
        ecosystem: "ethereum",
        isCoin: true,
      };
    }

    return mappedAssets;
  };

  return (
    <NetworkContext.Provider
      value={{
        submitDelegation,
        nexusQuerier,
        primeWallet,
        loading,
        loadingHistory,
        assets,
        transactions,
        fee,
        receivingAddresses,
        estimateFee,
        submitTransaction,
        addPendingTransaction,
        getTransactions,
        getAssets,
        stake,
        setWallets,
      }}
    >
      {children}

      <Outlet />
    </NetworkContext.Provider>
  );
};

export { NetworkContext, NetworkProvider };
