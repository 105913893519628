import { useState } from "react";
import "./floatingActionButton.css";
import Open from "./../../../../assets/open.svg";
import { useNavigate } from "react-router";

export const FloatingActionButton = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  return (
    <div className="fab-btn-container">
      <div className={` fab-actions-container ${open ? "fade-in" : "fade-out"}`}>
        <button
          className="fab-action"
          onClick={() => {
            if (open) navigate("/transaction/new");
          }}
        >
          <div className="fab-action-name">
            <ArrowUp />
            Send
          </div>
          <Open />
        </button>
        <div className="separator" />
        <button
          className="fab-action"
          onClick={() => {
            if (open) navigate("/transaction/receive");
          }}
        >
          <div className="fab-action-name">
            <ArrowDown />
            Receive
          </div>
          <Open />
        </button>
      </div>
      <button className="fab-btn" onClick={() => setOpen((val) => !val)}>
        <div className="fab-inline-border">
          <div className={` "fab-btn-icon" ${open ? "fab-btn-open" : "fab-btn-closed"}`}>
            <New />
          </div>
        </div>
      </button>
    </div>
  );
};

const ArrowUp = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="19" viewBox="0 0 16 19" fill="none">
      <path
        d="M6.99584 17.0664C6.99584 17.621 7.44542 18.0706 8 18.0706C8.55458 18.0706 9.00416 17.621 9.00416 17.0664L6.99584 17.0664ZM8.71005 0.289845C8.3179 -0.102303 7.6821 -0.102303 7.28995 0.289845L0.899537 6.68026C0.507389 7.07241 0.507389 7.70821 0.899537 8.10036C1.29168 8.4925 1.92748 8.4925 2.31963 8.10036L8 2.41998L13.6804 8.10036C14.0725 8.4925 14.7083 8.4925 15.1005 8.10036C15.4926 7.70821 15.4926 7.07241 15.1005 6.68026L8.71005 0.289845ZM9.00416 17.0664L9.00416 0.999891L6.99584 0.999891L6.99584 17.0664L9.00416 17.0664Z"
        fill="#737979"
      />
    </svg>
  );
};

const ArrowDown = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="22" viewBox="0 0 17 22" fill="none">
      <path
        d="M9.42603 1C9.42603 0.445419 8.97646 -0.00415716 8.42188 -0.00415719C7.86729 -0.00415721 7.41772 0.445419 7.41772 1L9.42603 1ZM7.71183 17.7766C8.10398 18.1687 8.73977 18.1687 9.13192 17.7766L15.5223 11.3861C15.9145 10.994 15.9145 10.3582 15.5223 9.96605C15.1302 9.5739 14.4944 9.5739 14.1022 9.96605L8.42187 15.6464L2.7415 9.96605C2.34936 9.5739 1.71356 9.5739 1.32141 9.96605C0.929263 10.3582 0.929263 10.994 1.32141 11.3861L7.71183 17.7766ZM7.41772 1L7.41772 17.0665L9.42603 17.0665L9.42603 1L7.41772 1Z"
        fill="#737979"
      />
      <rect y="20.2305" width="16.2185" height="1.76929" rx="0.884647" fill="#737979" />
    </svg>
  );
};

const New = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M6.28196 15.4648V0.464844H8.82741V15.4648H6.28196ZM0.0546875 9.23757V6.69212H15.0547V9.23757H0.0546875Z"
        fill="#F7F7F7"
      />
    </svg>
  );
};
