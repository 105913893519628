import { Back, Button, useInput, Drawer } from "../../components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const ConfirmationDrawer = ({ isOpen, toggleDrawer }) => {
  const [step, setStep] = useState(0);
  const navigate = useNavigate();

  const [value, valueInput] = useInput({
    label: "",
    placeholder: "DELETE",
  });

  const onAction = () => {
    if (step === 1) {
      window.localStorage.clear();
      navigate("/");
    } else setStep(step + 1);
  };

  return (
    <Drawer {...{ isOpen, toggleDrawer }}>
      <div className="drawer-header">
        <div className="drawer-padding-top-3">
          <Back
            action={() => {
              if (step > 0) setStep((step) => step - 1);
              else toggleDrawer((val) => !val);
            }}
          />
        </div>
        {step === 0 ? (
          <span className="subtitle">
            <span>Are you sure you want to </span>
            <span className="settings-red">delete </span>
            <span>your account?</span>
          </span>
        ) : (
          <span className="subtitle">
            <span>Confirm you want to delete your account by typing</span>
            <span className="settings-red uppercase"> ‘DELETE’ </span>
            <span>in the field below</span>
          </span>
        )}
      </div>
      {step === 0 ? (
        <span className="settings-drawer-content">
          Your account and funds will be deleted and they cannot be recovered after.
        </span>
      ) : (
        <>{valueInput}</>
      )}

      <Button
        label={step === 0 ? "Continue" : "Delete"}
        color={step === 0 ? "outline" : ""}
        disabled={step === 1 && value !== "DELETE"}
        action={() => onAction()}
      />
    </Drawer>
  );
};
