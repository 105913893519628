import "./maxAmount.css";

export const MaxAmount = ({ big = false }) => {
  return (
    <div className={`max-amount ${big ? "big" : "small"}`}>
      {big ? (
        <svg width="12" height="9" viewBox="0 0 12 9" fill="none">
          <path d="M6.03943 0.0410156L11.8718 8.78962H0.207031L6.03943 0.0410156Z" fill="#077368" />
        </svg>
      ) : (
        <svg width="8" height="7" viewBox="0 0 8 7" fill="none">
          <path d="M4 0.5L8 6.5H0L4 0.5Z" fill="#077368" />
        </svg>
      )}
      <span>MAX</span>
    </div>
  );
};
