import "./../transactions.css";
import { useContext, useEffect, useState } from "react";
import { FilterDrawer } from "./filterDrawer";
import { formatAmount } from "../../../utils/formatAmount";
import { Spinner } from "../../../components";
import { NetworkContext } from "../../../contexts/networkContext";
import { FloatingActionButton } from "./floatingActionButton/floatingActionButton";
import { formatDate } from "../../../utils/formatDate";
import { useNavigate } from "react-router";
import { TransactionTypeIcon } from "./transactionTypeIcon";
import { useInterval } from "../../../hooks/useInterval";

export const ListTransactionsPage = () => {
  const navigate = useNavigate();
  const { transactions, loadingHistory, getTransactions } = useContext(NetworkContext);
  const [filteredTransactions, setFilteredTransactions] = useState(transactions);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [activeFilters, setActiveFilters] = useState(0);
  const [txFilters, setTxFilters] = useState({ token: "all", network: "all" });

  useInterval(() => {
    getTransactions();
  }, 8000);

  useEffect(() => {
    filter(txFilters);
  }, [transactions]);

  const filter = (value) => {
    let filters = 0;
    filters = value.token !== "all" ? filters + 1 : filters;
    filters = value.network !== "all" ? filters + 1 : filters;
    setActiveFilters(filters);

    setTxFilters(value);

    setFilteredTransactions(
      transactions.filter(
        (tx) =>
          (tx.network === value.network || value.network === "all") &&
          (tx.tokens?.includes(value.token) || value.token === "all")
      )
    );
  };

  return (
    <div className="transaction-container">
      <div className="transactions-header">
        <span className="title">Transactions</span>
        {(filteredTransactions.length > 0 || activeFilters > 0) && (
          <button className="transactions-filter" onClick={() => setIsFilterOpen((val) => !val)}>
            <span>Filter</span>
            {activeFilters > 0 && <div className="active-filter">{activeFilters}</div>}
          </button>
        )}
      </div>
      {loadingHistory ? (
        <Spinner />
      ) : (
        <div className="transaction-list">
          {filteredTransactions.length === 0 ? (
            <>
              <div className="no-assets"> No transactions at the moment</div>
              <FloatingActionButton />
            </>
          ) : (
            filteredTransactions.map((transaction) => {
              const { hash, date, type, status, amount, network, fee, token } = transaction;

              return (
                <div
                  key={hash}
                  className="transaction-column"
                  onClick={() => navigate(`/list-transactions/${hash}`)}
                >
                  <div className="transaction-date">{formatDate(new Date(date))}</div>

                  <div className="transaction-details">
                    <TransactionTypeIcon {...{ type, network }} />

                    <div className="transaction-status capitalize">
                      {type}
                      <span>{status}</span>
                    </div>

                    <div className="transaction-total">
                      <span className={`${type === "receive" && "transaction-receive"}`}>
                        {type === "send" && "-"}
                        {type === "receive" && "+"}
                        {formatAmount(amount)} {token?.name}
                      </span>
                      <span>fee {formatAmount(fee)} </span>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      )}

      <FilterDrawer
        isOpen={isFilterOpen}
        toggleDrawer={setIsFilterOpen}
        filter={filter}
        filteredBy={txFilters}
      />
    </div>
  );
};
