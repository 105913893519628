import { encode } from "cbor-web";

export class CardanoConnectorMetadata {
  #querier;

  namespace;
  methods = [
    "cardano_getExtensions",
    "cardano_getNetworkId",
    "cardano_getUtxos",
    "cardano_getBalance",
    "cardano_getUsedAddresses",
    "cardano_getUnusedAddresses",
    "cardano_getChangeAddress",
    "cardano_getRewardAddress",
    "cardano_submitTx",
  ];
  approvalRequiredMethods = ["cardano_submitTx"];

  /**
   * @param {import("./querier").default} querier
   */
  constructor(querier, magicNumber) {
    this.#querier = querier;
    this.chainId = this.#querier.getNetworkId();
    this.namespace = `cip34:${this.#querier.getNetworkId()}-${magicNumber}`;
  }

  cardano_getExtensions() {
    return { cip: 30 };
  }

  cardano_getNetworkId() {
    return this.#querier.getNetworkId();
  }

  async cardano_getUtxos() {
    const utxos = await this.#querier.getUTXOs();
    return utxos.to_js_value();
  }

  async cardano_getBalance() {
    const balances = await this.#querier.getBalance();
    return encode(balances).toString("hex");
  }

  cardano_getUsedAddresses() {
    return [this.#querier.getReceiveAddress()];
  }

  cardano_getUnusedAddresses() {
    return [this.#querier.getReceiveAddress()];
  }

  cardano_getChangeAddress() {
    return this.#querier.getChangeAddress();
  }

  cardano_getRewardAddress() {
    return this.#querier.getStakeAddress();
  }

  accounts() {
    return [
      `${this.namespace}:${this.#querier.getReceiveAddress()}`,
      `${this.namespace}:${this.#querier.getStakeAddress()}`,
    ];
  }
}

export class CardanoConnector {
  #transactor;
  #querier;

  /**
   * @param {import("./transactor").default} transactor
   * @param {import("./querier").default} querier
   */
  constructor(transactor, querier, magicNumber) {
    this.#transactor = transactor;
    this.#querier = querier;
    this.namespace = `cip34:${this.#querier.getNetworkId()}-${magicNumber}`;
  }

  async cardano_submitTx(params) {
    const tx = params[0];
    return this.#transactor.submitTx(tx);
  }
}
