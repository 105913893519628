import "./settings.css";
import { useState } from "react";
import { ConfirmationDrawer } from "./confirmationDrawer";

export const SettingsPage = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <div className="settings-container">
      <div className="settings-header">
        <span className="title">Settings</span>
      </div>
      <div className="settings-inline">
        <div className="settings-grow">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="17"
            viewBox="0 0 15 17"
            fill="none"
          >
            <path
              d="M14.375 2.5H11.25V1.875C11.25 1.37772 11.0525 0.900806 10.7008 0.549175C10.3492 0.197544 9.87228 0 9.375 0H5.625C5.12772 0 4.65081 0.197544 4.29917 0.549175C3.94754 0.900806 3.75 1.37772 3.75 1.875V2.5H0.625C0.45924 2.5 0.300269 2.56585 0.183058 2.68306C0.0658481 2.80027 0 2.95924 0 3.125C0 3.29076 0.0658481 3.44973 0.183058 3.56694C0.300269 3.68415 0.45924 3.75 0.625 3.75H1.25V15C1.25 15.3315 1.3817 15.6495 1.61612 15.8839C1.85054 16.1183 2.16848 16.25 2.5 16.25H12.5C12.8315 16.25 13.1495 16.1183 13.3839 15.8839C13.6183 15.6495 13.75 15.3315 13.75 15V3.75H14.375C14.5408 3.75 14.6997 3.68415 14.8169 3.56694C14.9342 3.44973 15 3.29076 15 3.125C15 2.95924 14.9342 2.80027 14.8169 2.68306C14.6997 2.56585 14.5408 2.5 14.375 2.5ZM6.25 11.875C6.25 12.0408 6.18415 12.1997 6.06694 12.3169C5.94973 12.4342 5.79076 12.5 5.625 12.5C5.45924 12.5 5.30027 12.4342 5.18306 12.3169C5.06585 12.1997 5 12.0408 5 11.875V6.875C5 6.70924 5.06585 6.55027 5.18306 6.43306C5.30027 6.31585 5.45924 6.25 5.625 6.25C5.79076 6.25 5.94973 6.31585 6.06694 6.43306C6.18415 6.55027 6.25 6.70924 6.25 6.875V11.875ZM10 11.875C10 12.0408 9.93415 12.1997 9.81694 12.3169C9.69973 12.4342 9.54076 12.5 9.375 12.5C9.20924 12.5 9.05027 12.4342 8.93306 12.3169C8.81585 12.1997 8.75 12.0408 8.75 11.875V6.875C8.75 6.70924 8.81585 6.55027 8.93306 6.43306C9.05027 6.31585 9.20924 6.25 9.375 6.25C9.54076 6.25 9.69973 6.31585 9.81694 6.43306C9.93415 6.55027 10 6.70924 10 6.875V11.875ZM10 2.5H5V1.875C5 1.70924 5.06585 1.55027 5.18306 1.43306C5.30027 1.31585 5.45924 1.25 5.625 1.25H9.375C9.54076 1.25 9.69973 1.31585 9.81694 1.43306C9.93415 1.55027 10 1.70924 10 1.875V2.5Z"
              fill="#F25041"
            />
          </svg>
          <button className="settings-red-action" onClick={() => setIsDrawerOpen((val) => !val)}>
            Delete account
          </button>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="14"
          viewBox="0 0 8 14"
          fill="none"
        >
          <path
            d="M7.8045 7.4502L1.13858 13.8135C1.07664 13.8726 1.00312 13.9195 0.922198 13.9515C0.841279 13.9835 0.75455 14 0.666963 14C0.579376 14 0.492647 13.9835 0.411727 13.9515C0.330807 13.9195 0.257282 13.8726 0.195349 13.8135C0.133415 13.7544 0.0842872 13.6842 0.0507692 13.607C0.0172512 13.5297 0 13.4469 0 13.3633C0 13.2797 0.0172512 13.1969 0.0507692 13.1197C0.0842872 13.0424 0.133415 12.9722 0.195349 12.9131L6.39049 7L0.195349 1.08689C0.0702687 0.967488 -1.31793e-09 0.805545 0 0.636685C1.31793e-09 0.467826 0.0702687 0.305882 0.195349 0.186481C0.320428 0.0670791 0.490073 1.2581e-09 0.666963 0C0.843852 -1.2581e-09 1.0135 0.0670791 1.13858 0.186481L7.8045 6.54979C7.86648 6.60889 7.91564 6.67907 7.94919 6.75632C7.98273 6.83357 8 6.91638 8 7C8 7.08362 7.98273 7.16643 7.94919 7.24368C7.91564 7.32093 7.86648 7.39111 7.8045 7.4502Z"
            fill="#DEE5DF"
          />
        </svg>
      </div>
      <ConfirmationDrawer isOpen={isDrawerOpen} toggleDrawer={setIsDrawerOpen} />
    </div>
  );
};
