import { CopyBtn } from "../../../components";

export const InfoRow = ({ label, value, copy = null }) => {
  return (
    <div className="details-row">
      <span>{label}</span>
      <div>
        <div className="details-item capitalize">
          {value}
          {copy && <CopyBtn data={copy} />}
        </div>
      </div>
    </div>
  );
};
