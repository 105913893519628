import { Receive } from "../../assets/receive";
import { Send } from "../../assets/send";
import { Stake } from "../../assets/stake";
import emoji from "../../assets/userEmoji.png";
import QrScanOpen from "../../assets/qrOpen.svg";
import { Tabs } from "./tabs";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { Spinner } from "../../components";
import { NetworkContext } from "../../contexts/networkContext";

export const DashboardPage = () => {
  const navigate = useNavigate();
  const { assets, loading, getAssets } = useContext(NetworkContext);

  useEffect(() => {
    if (!loading) getAssets();
  }, [loading]);

  return (
    <div className="dash-container">
      <div className="dash-header">
        <div className="dash-header">
          <img src={emoji} alt="" className="dash-icon" />
          <span>My wallet</span>
        </div>
        <div className="dash-header">
          <QrScanOpen onClick={() => navigate("/wallet-connect/qr")} />
        </div>
      </div>

      <div className="dash-center">
        <div className="dash-actions">
          <button className="dash-btn" onClick={() => navigate("/transaction/new")}>
            <div className="dash-bcg">
              <Send width={51} height={50} />
            </div>
            <span className="das-btn-label">Send</span>
          </button>
          <button className="dash-btn" onClick={() => navigate("/transaction/receive")}>
            <div className="dash-bcg">
              <Receive width={51} height={50} />
            </div>
            <span className="das-btn-label">Receive</span>
          </button>
          <button onClick={() => navigate("/stake-on-prime")}>
            <div className="dash-bcg">
              <Stake width={51} height={50} />
            </div>
            <span className="das-btn-label">Stake</span>
          </button>
        </div>
      </div>
      <div className="divider" />

      {loading ? <Spinner /> : <Tabs {...{ list: assets }} />}
    </div>
  );
};
