// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-content {
    display: flex;
    flex-direction: column;
    gap: 60px;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
  
  .login-welcome {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .login-error-hint {
    padding-top: 10px;
    color: var(--red);
  }
  
  .password-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
  }`, "",{"version":3,"sources":["webpack://./src/pages/login/login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,OAAO;IACP,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,SAAS;EACX;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,WAAW;EACb","sourcesContent":[".login-content {\n    display: flex;\n    flex-direction: column;\n    gap: 60px;\n    flex: 1;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .login-welcome {\n    align-items: center;\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n  }\n  \n  .login-error-hint {\n    padding-top: 10px;\n    color: var(--red);\n  }\n  \n  .password-container {\n    display: flex;\n    flex-direction: column;\n    gap: 5px;\n    width: 100%;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
