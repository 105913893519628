// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.connect-header {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.app {
  display: inline-flex;
  padding: 8px 14px;
  align-items: center;
  gap: 14px;

  border-radius: 49px;
  border: 1px solid #fff;
}

.connect-actions {
  gap: 15px;
}

.connect-msg {
  text-align: center;
}

.connect-icon {
  width: 27px;
  height: 27px;
}

.qr-video-container {
  width: 98%;
  margin: auto;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/pages/walletConnect/styles.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,oBAAoB;EACpB,iBAAiB;EACjB,mBAAmB;EACnB,SAAS;;EAET,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".connect-header {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}\n\n.app {\n  display: inline-flex;\n  padding: 8px 14px;\n  align-items: center;\n  gap: 14px;\n\n  border-radius: 49px;\n  border: 1px solid #fff;\n}\n\n.connect-actions {\n  gap: 15px;\n}\n\n.connect-msg {\n  text-align: center;\n}\n\n.connect-icon {\n  width: 27px;\n  height: 27px;\n}\n\n.qr-video-container {\n  width: 98%;\n  margin: auto;\n  overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
