export const chainConfig = {
  nexus: {
    name: "nexus",
    jsonRpcUrl: "https://testnet.af.route3.dev/json-rpc/nexus",
    indexerUrl: "https://explorer-testnet.af.route3.dev/api",
    chainId: "9070",
    // jsonRpcUrl: "http://localhost:8545/",
    // indexerUrl: "http://localhost:3000/api",
    // chainId: "33221",
    coin: "Apex",
    coinSymbol: "APEX",
  },
  vector: {
    name: "vector",
    blockfrostUrl: "https://testnet.af.route3.dev/blockfrost/vector/",
    ogmiosUrl: "https://testnet.af.route3.dev/ogmios/vector/",
    // blockfrostUrl: "http://localhost:3033",
    // ogmiosUrl: "wss://localhost:1337",
    networkId: "testnet",
    magicNumber: "3311",
    coin: "Apex",
    coinSymbol: "APEX",
  },
  prime: {
    name: "prime",
    blockfrostUrl: "https://testnet.af.route3.dev/blockfrost/prime/",
    ogmiosUrl: "https://testnet.af.route3.dev/ogmios/prime/",
    // networkId: "testnet",
    // magicNumber: "3312",
    coin: "Apex",
    coinSymbol: "APEX",
  },
};

export const stakingUrl = "https://testnet.af.route3.dev/";

export const SENTRY_AUTH_TOKEN =
  "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";
