// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toast {
  border-radius: 38px;
  background: rgba(255, 255, 255, 0.9);
  color: var(--Apex-fusion-black);
  font-size: 20px;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  padding: 9px 23px;
  position: absolute;
  left: 38%;
  top: 60px;
  z-index: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/toast/toast.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,oCAAoC;EACpC,+BAA+B;EAC/B,eAAe;;EAEf,oBAAoB;EACpB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;;EAET,iBAAiB;EACjB,kBAAkB;EAClB,SAAS;EACT,SAAS;EACT,UAAU;AACZ","sourcesContent":[".toast {\n  border-radius: 38px;\n  background: rgba(255, 255, 255, 0.9);\n  color: var(--Apex-fusion-black);\n  font-size: 20px;\n\n  display: inline-flex;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n\n  padding: 9px 23px;\n  position: absolute;\n  left: 38%;\n  top: 60px;\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
