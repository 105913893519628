import "./../transactions.css";
import { Button, Drawer, NavHeader } from "../../../components";
import Open from "./../../../assets/open.svg";
import { useContext, useEffect, useState } from "react";
import NexusIcon from "./../../../assets/nexus.svg";
import VectorIcon from "./../../../assets/vector.svg";
import PrimeIcon from "./../../../assets/prime.svg";
import { NetworkContext } from "../../../contexts/networkContext";
import { useSearchParams } from "react-router-dom";
import { getUniqueAssetsList } from "../../../utils/getUniqueAssetsList";

export const FilterDrawer = ({ isOpen, toggleDrawer, filter, filteredBy }) => {
  const { assets } = useContext(NetworkContext);
  const [openedFilter, setOpenedFilter] = useState();
  const [network, setNetwork] = useState("all");
  const [token, setToken] = useState("all");
  const [searchParams] = useSearchParams();
  const [uniqueAssets, setUniqueAssets] = useState();

  useEffect(() => {
    setToken(searchParams.get("asset") ?? "all");
  }, []);

  useEffect(() => {
    filter({ network, token });
  }, [network, token]);

  useEffect(() => {
    setUniqueAssets(getUniqueAssetsList(assets));
  }, [assets]);

  const goBack = () => {
    if (openedFilter !== null) setOpenedFilter(null);
    else toggleDrawer((val) => !val);
  };

  return (
    <Drawer {...{ isOpen, toggleDrawer }}>
      <NavHeader {...{ label: openedFilter ?? "Filter", action: goBack }} />

      {!openedFilter && (
        <div>
          <button className="transactions-header" onClick={() => setOpenedFilter("network")}>
            <span>Network</span>
            <div className="sub-filter-open">
              {filteredBy.network} <Open />
            </div>
          </button>
          <div className="divider" />
          <button className="transactions-header" onClick={() => setOpenedFilter("token")}>
            <span>Token</span>
            <div className="sub-filter-open">
              {filteredBy.token}
              <Open />
            </div>
          </button>
        </div>
      )}
      {openedFilter === "network" && (
        <div className="network-filter radio-container">
          <label className="network-details">
            <RadioBtn {...{ value: "all", newValue: network, setValue: setNetwork }} />
            <span>All Networks</span>
          </label>
          <div className="network-details">
            <RadioBtn {...{ value: "nexus", newValue: network, setValue: setNetwork }} />
            <NexusIcon />
          </div>
          <div className="network-details">
            <RadioBtn {...{ value: "vector", newValue: network, setValue: setNetwork }} />
            <VectorIcon />
          </div>
          <div className="network-details">
            <RadioBtn {...{ value: "prime", newValue: network, setValue: setNetwork }} />
            <PrimeIcon />
          </div>
        </div>
      )}
      {openedFilter === "token" && (
        <div className="network-filter radio-container">
          <div className="network-details">
            <RadioBtn
              {...{
                value: "all",
                newValue: token,
                setValue: setToken,
              }}
            />
            <span>All tokens</span>
          </div>

          {uniqueAssets.map((asset) => {
            const { id, icon, name } = asset;
            return (
              <div key={id} className="network-details">
                <RadioBtn
                  {...{
                    value: name,
                    newValue: token,
                    setValue: setToken,
                  }}
                />
                <div className="asset">
                  {icon ? (
                    <img src={icon} alt="" className="filter-asset-icon" />
                  ) : (
                    <UnknownTokenIcon className="filter-asset-icon" />
                  )}
                  <div className="asset-name">
                    <div>{name}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <Button
        label="Reset all"
        color="outline"
        action={() => {
          setNetwork("all");
          setToken("all");
        }}
      />
    </Drawer>
  );
};

const RadioBtn = ({ value, newValue, setValue }) => {
  return (
    <input
      type="radio"
      name="network"
      value={value}
      checked={newValue === value}
      onChange={(e) => setValue(e.currentTarget.value)}
    />
  );
};
