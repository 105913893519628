import "./styles.css";
import { Button } from "../../components";
import { shortAddress } from "../../utils/shortAddress";
import { formatEther } from "ethers";
import { NetworkIcon } from "../../components/networkIcon";

export const SessionRequest = (props) => {
  const {
    dapp = {
      name: "",
      url: "",
      iconUrl: "",
    },
    network = "",
    requestType = "", // "sendTransaction" | "signMessage"
    data = null,
    approve = () => {},
    reject = () => {},
  } = props;

  return (
    <>
      <div className="connect-header">
        <div className="subtitle">
          {requestType === "sendTransaction" ? "Transaction Request" : "Sign Message Request"}
        </div>
        <div className="app">
          {dapp.iconUrl && <img src={dapp.iconUrl} alt="" className="connect-icon" />}
          <span>{dapp.url.replace("https://", "")}</span>
        </div>
      </div>
      {requestType === "sendTransaction" ? (
        <div className="transaction-selection no-gap">
          {data?.value && (
            <div className="total-row">
              <div className="network-details">
                <span>Value</span>
              </div>

              <div className="inline">{formatEther(data.value)}</div>
            </div>
          )}

          {network && (
            <div className="total-row">
              Network
              <NetworkIcon {...{ network }} />
            </div>
          )}

          {data?.from && (
            <div className="total-row">
              <span>From</span>
              <span>{shortAddress(data.from)}</span>
            </div>
          )}

          {data?.to && (
            <div className="total-row">
              <span>To</span>
              <span>{shortAddress(data.to)}</span>
            </div>
          )}

          {data?.gasCost && (
            <>
              <div className="divider" />

              <div className="total-row">
                <span>Fee estimate</span>
                <span>{formatEther(data.gasCost)}</span>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="small-text connect-msg">{data.msg}</div>
      )}
      <div className="inline connect-actions">
        <Button label="Reject" color="outline" action={reject} />
        <Button label="Approve" action={approve} />
      </div>
    </>
  );
};
