import NexusIcon from "./../../../assets/nexus.svg";
import VectorIcon from "./../../../assets/vector.svg";
import PrimeIcon from "./../../../assets/prime.svg";
import { formatAmount } from "../../../utils/formatAmount";

export const NetworkList = (props) => {
  const { setNetwork, networkList } = props;

  return (
    <div className="transaction-content">
      <span className="small-text">Network</span>
      <div className="network-list">
        {networkList.map((el, index) => {
          const { name, token, amount } = el;
          let disabled = Number(amount) === 0;

          return (
            <button
              key={index}
              onClick={() => {
                if (!disabled) setNetwork(name);
              }}
              className={`network-balance ${disabled && "network-disabled"}`}
            >
              {name === "nexus" && <NexusIcon />}
              {name === "vector" && <VectorIcon />}
              {name === "prime" && <PrimeIcon />}
              {token && (
                <div>
                  Balance: {formatAmount(amount)}
                  <span className="uppercase"> {token}</span>
                </div>
              )}
            </button>
          );
        })}
      </div>
    </div>
  );
};
