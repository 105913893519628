import { Button } from "../../components";
import Route3Icon from "./../../assets/route3.svg";

export const Wallet = ({ onContinue }) => {
  return (
    <>
      <div className="logo-content">
        <div className="route3-logo">
          <Route3Icon />
          <div className="red-text">wallet</div>
        </div>

        <div className="subtitle onboarding-subtitle word-break">
          {`Route3 Wallet will 
          enable you to interact 
          with Apex Fusion`}
        </div>
      </div>

      <Button label="Create Wallet" action={onContinue} />
    </>
  );
};
