import { useContext, useEffect, useState } from "react";
import { Spinner, NavHeader, NetworkIcon } from "../../../components";
import "./../transactions.css";
import { useParams } from "react-router";
import { shortAddress } from "../../../utils/shortAddress";
import { NetworkContext } from "../../../contexts/networkContext";
import { formatDate } from "../../../utils/formatDate";
import { TransactionTypeIcon } from "./transactionTypeIcon";
import { formatAmount } from "../../../utils/formatAmount";
import { InfoRow } from "./infoRow";

export const SingleTransactionPage = () => {
  const transactionId = useParams().id;
  const { transactions } = useContext(NetworkContext);
  const [transaction, setTransaction] = useState({});
  const { hash, date, type, status, amount, network, fee, token, to, from } = transaction ?? {};

  useEffect(() => {
    setTransaction(transactions.find((el) => el.hash === transactionId));
  }, [transactions]);

  return (
    <div className="transaction-container">
      {!hash ? (
        <Spinner />
      ) : (
        <>
          <NavHeader {...{ label: type }} />

          <div className="details-content">
            <div className="details-amount">
              <Amount {...{ amount, token }} />
            </div>

            <div className="divider" />

            {from && <InfoRow label="From" value={shortAddress(from)} copy={from} />}

            {to && <InfoRow label="To" value={shortAddress(to)} copy={to} />}

            <InfoRow label="Network" value={<NetworkIcon {...{ network }} />} />

            {date && <InfoRow label="Completed on" value={formatDate(date, "timeFirst")} />}

            <InfoRow label="Amount" value={<Amount {...{ amount, token }} />} />

            {fee && <InfoRow label="Fee" value={<Amount {...{ amount: fee, token }} />} />}
          </div>

          <div className="divider" />

          <InfoRow label={<TransactionType {...{ type, network }} />} value={status} />

          {type === "staking" && <Button label="View on Adastat" color="outline" />}
        </>
      )}
    </div>
  );
};

const Amount = ({ amount, token }) => {
  return (
    <span>
      {formatAmount(amount)} {token}
    </span>
  );
};

const TransactionType = ({ type, network }) => {
  return (
    <div className="details-item">
      <TransactionTypeIcon {...{ type, network }} />
      <span className="capitalize">{type}</span>
    </div>
  );
};
