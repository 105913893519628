import { useState } from "react";
import "./checkbox.css";

export const Checkbox = ({ value, label, checked, onChange = () => {} }) => {
  return (
    <label className="checkbox-container">
      <span> {label}</span>

      <input type="checkbox" value={value} checked={checked} onChange={onChange} />
      <span className="check-mark"></span>
    </label>
  );
};

// export function useCheckbox(props) {
//   // const { value = "" } = props;
//   const [checked, setChecked] = useState(false);

//   const input = (
//     <span className="checkbox-container">
//       {/* <span> {label}</span> */}

//       <input
//         type="checkbox"
//         value={value}
//         checked={checked}
//         onChange={() => setChecked((val) => !val)}
//       />
//       <span className="check-mark"></span>
//     </span>
//   );
//   return [checked, input];
// }
