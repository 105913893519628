import { createContext, useContext, useEffect, useState } from "react";
import { Core } from "@walletconnect/core";
import { Web3Wallet } from "@walletconnect/web3wallet";
import { buildApprovedNamespaces, getSdkError } from "@walletconnect/utils";
import { formatJsonRpcError, formatJsonRpcResult } from "@walletconnect/jsonrpc-utils";
import { toUtf8String } from "ethers";
import { NetworkContext } from "./networkContext";
import { Drawer } from "../components";
import { chainConfig } from "../../config";
import { SessionProposal } from "../pages/walletConnect/sessionProposal";
import { SessionRequest } from "../pages/walletConnect/sessionRequest";
import {
  EthereumConnector,
  EthereumConnectorMetadata,
} from "../services/blockchain/ethereum/wallet/connector";
import {
  CardanoConnector,
  CardanoConnectorMetadata,
} from "../services/blockchain/cardano/wallet/connector";
import { LoginDrawer } from "../pages/login/loginDrawer";
import { EthereumTransactor } from "../services/blockchain/ethereum/wallet/transactor";
import CardanoTransactor from "../services/blockchain/cardano/wallet/transactor";

export const WalletConnectContext = createContext();

export const WalletConnectProvider = ({ children }) => {
  /**
   * @type {[import("@walletconnect/web3wallet").Web3Wallet, React.Dispatch<React.SetStateAction<import("@walletconnect/web3wallet").Web3Wallet>>]}
   */
  const [web3wallet, setWeb3wallet] = useState();
  const [showDrawer, setShowDrawer] = useState(false);
  const [showPasswordDrawer, setShowPasswordDrawer] = useState(false);
  /**
   * @type {[SessionMessage[], React.Dispatch<React.SetStateAction<SessionMessage[]>>]}
   */
  const [messages, setMessages] = useState([]);
  /**
   * @type {[SessionMessage | undefined, React.Dispatch<React.SetStateAction<SessionMessage | undefined>>]}
   */
  const [currentMessage, setCurrentMessage] = useState();
  /**
   * @type {[{ topic: string }[], React.Dispatch<React.SetStateAction<{ topic: string }[]>>]}
   */
  const [sessions, setSessions] = useState([]);

  /**
   * @type {{ nexusQuerier: import("../services/blockchain/ethereum/wallet/querier").EthereumQuerier, vectorQuerier: import("../services/blockchain/cardano/wallet/querier").default}}
   */
  const { nexusQuerier, vectorQuerier } = useContext(NetworkContext);
  /**
   * @type {[EthereumConnectorMetadata, React.Dispatch<React.Set]}
   */
  const [nexusConnectorMetadata, setNexusConnectorMetadata] = useState();
  const [vectorConnectorMetadata, setVectorConnectorMetadata] = useState();
  const [connectorMetadatas, setConnectorMetadatas] = useState([]);

  useEffect(() => {
    if (!nexusQuerier || !vectorQuerier) {
      return;
    }

    const nexusConnMtdt = new EthereumConnectorMetadata(
      chainConfig.nexus.chainId,
      nexusQuerier.getAddress()
    );
    const vectorConnMtdt = new CardanoConnectorMetadata(
      vectorQuerier,
      chainConfig.vector.magicNumber
    );
    setNexusConnectorMetadata(nexusConnMtdt);
    setVectorConnectorMetadata(vectorConnMtdt);
    setConnectorMetadatas([nexusConnMtdt, vectorConnMtdt]);
    initWalletConnect().catch((error) => console.error("Error initializing WalletConnect", error));
  }, [nexusQuerier, vectorQuerier]);

  useEffect(() => {
    if (web3wallet) {
      initWalletConnectListeners();
      setExistingSessions();
    }
  }, [web3wallet]);

  useEffect(() => {
    if (messages.length > 0) {
      setCurrentMessage(messages[0]);
      setShowDrawer(true);
    } else {
      setShowDrawer(false);
      setCurrentMessage(undefined);
    }
  }, [messages]);

  const initWalletConnect = async () => {
    const web3wallet = await Web3Wallet.init({
      core: new Core({
        projectId: "0145b0fc31d1bd8456a109e53affbbe3",
      }),
      metadata: {
        name: "ApexFusion Wallet",
        description: "Route3 ApexFusion Wallet",
        url: window.location.host,
        icons: [],
      },
    });
    setWeb3wallet(() => web3wallet);
  };

  const initWalletConnectListeners = () => {
    console.log("Starting to listen for WalletConnect events");

    web3wallet.on("session_proposal", handleSessionProposal);
    web3wallet.on("proposal_expire", handleProposalExpire);
    web3wallet.engine.signClient.events.on("session_update", handleSessionUpdate);
    web3wallet.on("session_expire", handleSessionExpire);
    web3wallet.on("session_delete", handleSessionDelete);
    web3wallet.on("session_request", handleSessionRequest);
    web3wallet.on("session_request_expire", handleSessionRequestExpire);
  };

  const setExistingSessions = () => {
    const existingSessions = Object.values(web3wallet.getActiveSessions()).map((session) => {
      return { topic: session.topic };
    });
    console.log("Existing sessions", existingSessions);
    setSessions((sessions) => [...sessions, ...existingSessions]);
  };

  /**
   * @param {import("@walletconnect/web3wallet").Web3WalletTypes.SessionProposal} proposal
   */
  const handleSessionProposal = (proposal) => {
    console.log("session_proposal", proposal);
    setMessages((msgs) => [...msgs, { id: proposal.id, type: "session_proposal", data: proposal }]);
  };

  /**
   * @param {import("@walletconnect/web3wallet").Web3WalletTypes.ProposalExpire} proposal
   */
  const handleProposalExpire = (proposal) => {
    console.log("proposal_expire", proposal);
    removeMessage(proposal.id);
  };

  /**
   * @param {import("@walletconnect/web3wallet").Web3WalletTypes.BaseEventArgs<{ namespaces: any }>} sessionUpdate
   */
  const handleSessionUpdate = async (sessionUpdate) => {
    console.log("session_update", sessionUpdate);

    const { topic, params } = sessionUpdate;
    const { acknowledged } = await web3wallet.updateSession({
      topic,
      namespaces: params.namespaces,
    });
    await acknowledged();
    // TODO: ask user for the approval of session update
    // setMessages((msgs) => [...msgs, { id: proposal.id, type: "session_update", data: sessionUpdate }]);
  };

  const handleSessionExpire = (session) => {
    console.log("session_expire", session);
    removeSession(session.topic);
  };

  /**
   * @param {import("@walletconnect/web3wallet").Web3WalletTypes.SessionDelete} session
   */
  const handleSessionDelete = (session) => {
    console.log("session_delete", session);
    removeSession(session.topic);
  };

  /**
   * @param {import("@walletconnect/web3wallet").Web3WalletTypes.SessionRequest} request
   */
  const handleSessionRequest = async (request) => {
    console.log("session_request", request);

    const { topic, id, params } = request;
    const method = params.request.method;

    const connectorMetadata = connectorMetadatas.find((c) => c.namespace === params.chainId);
    if (!connectorMetadata) {
      await rejectRequest(id, topic, "Unsupported chain.");
      return;
    }

    if (!connectorMetadata.methods.includes(method)) {
      await rejectRequest(id, topic, "Unsupported method.");
      return;
    }

    // until this point is done correctly
    if (connectorMetadata.approvalRequiredMethods.includes(method)) {
      setMessages((msgs) => [
        ...msgs,
        { id: request.id, type: "session_request", data: { connectorMetadata, request } },
      ]);
    } else {
      await preApproveRequest(request, connectorMetadata);
    }
  };

  /**
   * @param {import("@walletconnect/web3wallet").Web3WalletTypes.SessionRequestExpire} request
   */
  const handleSessionRequestExpire = (request) => {
    console.log("session_request_expire", request);
    removeMessage(request.id);
  };

  /**
   * @param {import("@walletconnect/web3wallet").Web3WalletTypes.SessionProposal} proposal
   */
  const approveProposal = async (proposal) => {
    const { id, params } = proposal;

    try {
      const approvedNamespaces = buildApprovedNamespaces({
        proposal: params,
        supportedNamespaces: {
          eip155: {
            chains: [nexusConnectorMetadata.namespace],
            methods: nexusConnectorMetadata.methods,
            events: [],
            accounts: [nexusConnectorMetadata.account()],
          },
          cip34: {
            chains: [vectorConnectorMetadata.namespace],
            methods: vectorConnectorMetadata.methods,
            events: [],
            accounts: vectorConnectorMetadata.accounts(),
          },
        },
      });

      const session = await web3wallet.approveSession({
        id,
        namespaces: approvedNamespaces,
      });
      console.log("Session approved", session);

      setSessions((sessions) => [...sessions, { topic: session.topic }]);

      removeMessage(id);
    } catch (error) {
      console.error("Error approving session", error.message);
      await rejectProposal(id);
    }
  };

  /**
   * @param {number} id
   */
  const rejectProposal = async (id) => {
    await web3wallet.rejectSession({
      id,
      reason: getSdkError("USER_REJECTED"),
    });
    console.log("Session rejected", id);

    removeMessage(id);
  };

  /**
   * @param {import("@walletconnect/web3wallet").Web3WalletTypes.SessionRequest} request
   * @param {EthereumConnectorMetadata | CardanoConnectorMetadata} connectorMetadata
   */
  const preApproveRequest = async (request, connectorMetadata) => {
    const { id, topic, params } = request;

    try {
      const result = await connectorMetadata[params.request.method](params.request.params);
      await web3wallet.respondSessionRequest({
        topic,
        response: formatJsonRpcResult(id, result),
      });
      console.log("Request pre-approved with result", result);
    } catch (err) {
      console.error("Error pre-approving request:", err);
      await rejectRequest(id, topic, "Something went wrong.");
    }
  };

  /**
   * @param {import("@walletconnect/web3wallet").Web3WalletTypes.SessionRequest} request
   * @param {EthereumConnector | CardanoConnector} connector
   */
  const approveRequest = async (request, connector) => {
    const { id, topic, params } = request;

    try {
      const result = await connector[params.request.method](params.request.params);
      await web3wallet.respondSessionRequest({
        topic,
        response: formatJsonRpcResult(id, result),
      });
      console.log("Request approved with result", result);

      removeMessage(id);
    } catch (err) {
      console.error("Error approving request:", err);
      await rejectRequest(id, topic, "Something went wrong.");
    }
  };

  /**
   * @param {number} id
   * @param {string} topic
   * @param {string} reason
   */
  const rejectRequest = async (id, topic, reason) => {
    await web3wallet.respondSessionRequest({
      topic,
      response: formatJsonRpcError(id, reason),
    });
    console.log("Request rejected with reason", reason);

    removeMessage(id);
  };

  /**
   * @param {string} topic
   */
  const removeSession = (topic) => {
    setSessions((sessions) => sessions.filter((s) => s.topic !== topic));
  };

  /**
   * @param {number} id
   */
  const removeMessage = (id) => {
    setMessages((msgs) => msgs.filter((msg) => msg.id !== id));
  };

  const getDrawerContent = () => {
    /** @type {SessionMessage} */
    const msg = currentMessage;
    switch (msg.type) {
      case "session_proposal": {
        const proposal = msg.data;
        const proposer = proposal.params.proposer;
        return (
          <>
            <SessionProposal
              dapp={{
                name: proposer.metadata.name,
                url: proposer.metadata.url,
                iconUrl: proposer.metadata.icons[0],
              }}
              approve={() => approveProposal(proposal)}
              reject={() => rejectProposal(proposal.id)}
            />
          </>
        );
      }
      case "session_request": {
        const { connectorMetadata, request } = msg.data;
        const { id, topic } = request;
        const { method, params } = request.params.request;

        let network, requestType, requestData;
        switch (request.params.chainId) {
          case nexusConnector.namespace: {
            network = chainConfig.nexus.name;

            switch (method) {
              case "eth_sendTransaction": {
                requestType = "sendTransaction";
                requestData = params[0];
                break;
              }
              case "personal_sign": {
                const msg = toUtf8String(params[0]);
                requestType = "signMessage";
                requestData = { msg };
                break;
              }
              default: {
                rejectRequest(id, topic, "Unsupported method.");
                return;
              }
            }

            break;
          }
          case vectorConnector.namespace: {
            network = chainConfig.vector.name;

            switch (method) {
              case "cardano_submitTx": {
                const tx = params[0];
                requestType = "sendTransaction";
                requestData = tx;
                break;
              }
              default: {
                rejectRequest(id, topic, "Unsupported method.");
                return;
              }
            }

            break;
          }
          default:
            rejectRequest(id, topic, "Unsupported chain.");
            return;
        }

        return (
          <>
            <SessionRequest
              dapp={{
                url: request.verifyContext.verified.origin,
              }}
              network={network}
              requestType={requestType}
              data={requestData}
              approve={() => setShowPasswordDrawer(true)}
              reject={() => rejectRequest(id, topic, "User rejected request.")}
            />
            <LoginDrawer
              isOpen={showPasswordDrawer}
              toggleDrawer={setShowPasswordDrawer}
              action={async (mnemonic) => {
                let connector;
                if (connectorMetadata.chainId === chainConfig.nexus.chainId) {
                  const transactor = new EthereumTransactor(mnemonic, chainConfig.nexus.jsonRpcUrl);
                  connector = new EthereumConnector(transactor);
                } else if (connectorMetadata.chainId === chainConfig.vector.networkId) {
                  const transactor = new CardanoTransactor(
                    chainConfig.vector.blockfrostUrl,
                    chainConfig.vector.ogmiosUrl,
                    chainConfig.vector.networkId,
                    chainConfig.vector.magicNumber,
                    mnemonic
                  );
                  connector = new CardanoConnector(
                    transactor,
                    vectorQuerier,
                    chainConfig.vector.magicNumber
                  );
                }

                await approveRequest(request, connector);
                setShowPasswordDrawer(false);
              }}
            />
          </>
        );
      }
    }
  };

  return (
    <WalletConnectContext.Provider value={{ web3wallet }}>
      {children}
      <Drawer isOpen={showDrawer}>{showDrawer && getDrawerContent()}</Drawer>
    </WalletConnectContext.Provider>
  );
};

/**
 * @typedef {Object} SessionProposalMessage
 * @property {number} id - The id of the message, equals to session proposal id.
 * @property {"session_proposal"} type - The type of the message, indicating it's a session proposal.
 * @property {import("@walletconnect/web3wallet").Web3WalletTypes.SessionProposal} data - The data associated with the session proposal.
 */

/**
 * @typedef {Object} SessionRequestMessage
 * @property {number} id - The id of the message, equals to session request id.
 * @property {"session_request"} type - The type of the message, indicating it's a session request.
 * @property {{ connectorMetadata: EthereumConnectorMetadata | CardanoConnectorMetadata, request: import("@walletconnect/web3wallet").Web3WalletTypes.SessionRequest }} data - The data associated with the session request.
 */

/**
 * Type representing either a session proposal message or a session request message.
 * @typedef {SessionProposalMessage | SessionRequestMessage} SessionMessage
 */
