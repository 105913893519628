// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copy {
  align-items: center;
  display: flex;
  justify-content: center;
  /* gap: 6px; */

  /* padding: 10px 0; */
}
`, "",{"version":3,"sources":["webpack://./src/components/copyBtn/copyBtn.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,cAAc;;EAEd,qBAAqB;AACvB","sourcesContent":[".copy {\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  /* gap: 6px; */\n\n  /* padding: 10px 0; */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
