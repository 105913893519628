export const formatDate = (date, form = "dateFirst") => {
  let formatted;
  let optionsDate = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  let optionsTime = {
    hour: "numeric",
    minute: "numeric",
  };

  if (form === "dateFirst") {
    formatted = `${new Intl.DateTimeFormat("en", optionsDate).format(date)} ${Intl.DateTimeFormat("en", optionsTime).format(date)}`;
  } else {
    formatted = `${new Intl.DateTimeFormat("en", optionsTime).format(date)} ${new Intl.DateTimeFormat("en-US", optionsDate).format(date)}`;
  }

  return formatted;
};
