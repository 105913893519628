// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination-container {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

.pagination-page {
  /* padding: 15px; */
  align-items: center;
  display: flex;
  justify-content: center;
  width: 42px;
  height: 42px;
}

.pagination-page-active {
  background-color: #242424;
}
`, "",{"version":3,"sources":["webpack://./src/components/pagination/pagination.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,oBAAoB;EACpB,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".pagination-container {\n  align-items: center;\n  display: inline-flex;\n  justify-content: center;\n}\n\n.pagination-page {\n  /* padding: 15px; */\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  width: 42px;\n  height: 42px;\n}\n\n.pagination-page-active {\n  background-color: #242424;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
