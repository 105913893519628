import "./onboarding.css";
import { TextArea } from "./textArea/textArea";
import { Button } from "./../../components";
import { CopyBtn } from "../../components/copyBtn/copyBtn";

export const Backup = ({ mnemonic, onContinue }) => {
  return (
    <>
      <div className="content">
        <div className="title">Back up your wallet</div>
        <div>
          Your secret recovery phrase is used to recover your crypto if you lose your phone or
          switch to a different wallet.
        </div>
        <div>
          Save these 24 words in a secure location, such as a password manager, and never share them
          with anyone.
        </div>
        <div>
          <TextArea value={mnemonic} />
          <div className="copy-phrase">
            <CopyBtn {...{ data: mnemonic }} />
            Copy to clipboard
          </div>
        </div>
      </div>

      <Button label="Continue" action={onContinue} />
    </>
  );
};
