import "./button.css";

export const Button = (props) => {
  const { label = "", action = () => {}, disabled = false, color = "" } = props;

  return (
    <button
      className={`btn ${disabled && "btn-disabled"} ${
        color === "outline" && "btn-outline"
      } ${color === "white" && "btn-white"}`}
      onClick={() => {
        if (!disabled) action();
      }}
    >
      <div className={`${color !== "white" ? "inline-border" : "inline-border-black"}`}>
        <div className="btn-label">{label}</div>
      </div>
    </button>
  );
};
