// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-center {
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding-bottom: 100px;
}

.additional-text {
  color: var(--text);
  font-size: 18px;
  font-weight: 400;
  width: 278px;
}

.error-img {
  margin-bottom: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/error/errorPage.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;EACT,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".error-center {\n  align-items: center;\n  justify-content: center;\n  gap: 15px;\n  padding-bottom: 100px;\n}\n\n.additional-text {\n  color: var(--text);\n  font-size: 18px;\n  font-weight: 400;\n  width: 278px;\n}\n\n.error-img {\n  margin-bottom: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
