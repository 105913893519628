import { Stake } from "./../../assets/stake";
import ApexToken from "./../../assets/apexToken.svg";
import { Button } from "../../components";
import { useNavigate } from "react-router";

const promoMaterial = [
  {
    title: "One at a time",
    description: "You can only delegate all of your funds to one stake pool at a time",
  },
  {
    title: "Switch any time",
    description: "You can switch to delegate to a different stake pool at any time",
  },
  {
    title: "Cancel whenever",
    description: "You can cancel your delegation at any time",
  },
  {
    title: "Get rewards",
    description: "You earn rewards from the network after a couple of weeks",
  },
];

export const StakingPromoPage = () => {
  const navigate = useNavigate();

  return (
    <div className="promo-container ">
      <div>
        <Stake />
      </div>

      <span className="title promo-title-padding">
        Stake <ApexToken /> APEX on PRIME to get rewards
      </span>

      <div className="promo-content">
        {promoMaterial.map((el, index) => {
          return <PromoLine key={index} {...el} />;
        })}
      </div>

      <Button label="Continue" action={() => navigate("/stake")} />
    </div>
  );
};

const PromoLine = ({ title, description }) => {
  return (
    <div className="promo-div">
      <Check />
      <div>
        <span className="promo-text">{title} </span>
        <br />
        <span>{description}</span>
      </div>
    </div>
  );
};

const Check = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="12"
        viewBox="0 0 16 12"
        fill="none"
      >
        <path
          d="M14.3469 0.313479C14.4481 0.27157 14.5565 0.25 14.666 0.25C14.7756 0.25 14.884 0.27157 14.9852 0.313479C15.0864 0.355389 15.1783 0.416818 15.2557 0.494257C15.3332 0.571695 15.3946 0.663629 15.4365 0.764811C15.4784 0.865994 15.5 0.974438 15.5 1.08395C15.5 1.19346 15.4784 1.30191 15.4365 1.40309C15.3946 1.50427 15.3332 1.59621 15.2557 1.67364L5.92308 11.0063C5.84569 11.0838 5.7538 11.1452 5.65265 11.1872C5.5515 11.2291 5.44308 11.2507 5.33358 11.2507C5.22408 11.2507 5.11566 11.2291 5.01451 11.1872C4.91336 11.1452 4.82127 11.0836 4.74389 11.0061L0.744258 7.00648C0.587862 6.85009 0.5 6.63797 0.5 6.41679C0.5 6.19561 0.587862 5.98349 0.744258 5.8271C0.900653 5.6707 1.11277 5.58284 1.33395 5.58284C1.55513 5.58284 1.76725 5.6707 1.92364 5.8271L5.33352 9.23883L14.0764 0.494222C14.1538 0.416799 14.2457 0.355383 14.3469 0.313479Z"
          fill="#DEE2E5"
        />
      </svg>{" "}
    </div>
  );
};
