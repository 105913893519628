import { generateMnemonic as genMnemonic, validateMnemonic } from "bip39";
import aes from "js-crypto-aes";
import { randomBytes } from "../../utils/random";
import { sha256 } from "../../utils/hash";

export function generateMnemonic() {
  const mnemonic = genMnemonic(256);
  return mnemonic;
}

export async function encryptMnemonic(mnemonic, password) {
  if (!mnemonic || !password) {
    throw new Error("failed to encrypt mnemonic: missing mnemonic or password");
  }

  // generate key from the password and random salt
  const passwordBytes = new Uint8Array(Buffer.from(password, "utf8"));
  const saltBytes = randomBytes(16);
  const keyBytes = sha256(Buffer.concat([passwordBytes, saltBytes]));

  // generate random iv
  const ivBytes = randomBytes(12);

  // encrypt mnemonic
  const mnemonicBytes = new Uint8Array(Buffer.from(mnemonic, "utf8"));
  const encryptedMnemonicBytes = await aes.encrypt(mnemonicBytes, keyBytes, {
    name: "AES-GCM",
    iv: ivBytes,
  });

  // store data to local storage
  const encryptedMnemonicHex = Buffer.from(encryptedMnemonicBytes).toString("hex");
  const saltHex = Buffer.from(saltBytes).toString("hex");
  const ivHex = Buffer.from(ivBytes).toString("hex");

  return {
    encryptedMnemonicHex,
    saltHex,
    ivHex,
  };
}

export async function tryDecryptMnemonic(encryptedMnemonicHex, saltHex, ivHex, password) {
  if (!encryptedMnemonicHex || !saltHex || !ivHex || !password) {
    throw new Error("failed to decrypt mnemonic: missing parameters");
  }

  const encryptedMnemonicBytes = new Uint8Array(Buffer.from(encryptedMnemonicHex, "hex"));
  const saltBytes = new Uint8Array(Buffer.from(saltHex, "hex"));
  const ivBytes = new Uint8Array(Buffer.from(ivHex, "hex"));

  // generate key from the password and random salt
  const passwordBytes = new Uint8Array(Buffer.from(password, "utf8"));
  const keyBytes = sha256(Buffer.concat([passwordBytes, saltBytes]));

  // decrypt mnemonic
  const decryptedMnemonicBytes = await aes.decrypt(encryptedMnemonicBytes, keyBytes, {
    name: "AES-GCM",
    iv: ivBytes,
  });
  const decryptedMnemonic = new TextDecoder().decode(decryptedMnemonicBytes);
  if (!validateMnemonic(decryptedMnemonic)) {
    return null;
  }
  return decryptedMnemonic;
}
