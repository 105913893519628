// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.input-label {
  padding-bottom: 18px;
}

.input-div {
  background: transparent;
  border: 1px solid #e0e2e4;

  align-items: center;
  display: inline-flex;
  justify-content: space-between;
  gap: 15px;

  padding: 10px 13px 10px 8px;
}

.input-field {
  background: transparent;
  border: none;
  color: white;
  outline: none;
  flex: 1;

  font-size: 18px;
  font-weight: 500;
  line-height: 140%;

  justify-content: flex-end;
  display: inline-flex;
}

.horizontal-center {
  align-items: center;
}

.input-paste-btn {
  background: #2a2d30;
  border: none;
  color: var(--text);
  outline: none;

  padding: 6px 12px;
}

.input-error {
  color: var(--red);
}

.input-error-hint {
  padding-top: 10px;
  color: var(--red);
}

.input-content {
  display: flex;
  flex: 1;
  gap: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/input/input.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,OAAO;AACT;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,uBAAuB;EACvB,yBAAyB;;EAEzB,mBAAmB;EACnB,oBAAoB;EACpB,8BAA8B;EAC9B,SAAS;;EAET,2BAA2B;AAC7B;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,OAAO;;EAEP,eAAe;EACf,gBAAgB;EAChB,iBAAiB;;EAEjB,yBAAyB;EACzB,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,aAAa;;EAEb,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,OAAO;EACP,SAAS;AACX","sourcesContent":[".input-container {\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n}\n\n.input-label {\n  padding-bottom: 18px;\n}\n\n.input-div {\n  background: transparent;\n  border: 1px solid #e0e2e4;\n\n  align-items: center;\n  display: inline-flex;\n  justify-content: space-between;\n  gap: 15px;\n\n  padding: 10px 13px 10px 8px;\n}\n\n.input-field {\n  background: transparent;\n  border: none;\n  color: white;\n  outline: none;\n  flex: 1;\n\n  font-size: 18px;\n  font-weight: 500;\n  line-height: 140%;\n\n  justify-content: flex-end;\n  display: inline-flex;\n}\n\n.horizontal-center {\n  align-items: center;\n}\n\n.input-paste-btn {\n  background: #2a2d30;\n  border: none;\n  color: var(--text);\n  outline: none;\n\n  padding: 6px 12px;\n}\n\n.input-error {\n  color: var(--red);\n}\n\n.input-error-hint {\n  padding-top: 10px;\n  color: var(--red);\n}\n\n.input-content {\n  display: flex;\n  flex: 1;\n  gap: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
