import "./login.css";
import { Button, useInput } from "./../../components";
import emoji from "./../../assets/userEmoji.png";
import { useContext, useEffect } from "react";
import { NetworkContext } from "./../../contexts/networkContext";

export const LoginPage = () => {
  const {} = useContext(NetworkContext);
  const [password, passwordInput, valid, setValidPassword] = useInput({
    placeholder: "Enter password",
    hideText: true,
    error: "Invalid password",
  });

  //TO DO: add Encode logic
  const encode = () => {
    setValidPassword(false);
  };

  useEffect(() => {
    if (!valid) setValidPassword(true);
  }, [password]);

  return (
    <div className="container">
      <div className="login-content">
        <div className="login-welcome">
          <img src={emoji} alt="" className="dash-icon" />
          <span className="title">Welcome back!</span>
        </div>

        <div className="password-container">
          <>{passwordInput}</>
        </div>
      </div>

      <Button label="Confirm" disabled={password === ""} action={encode} />
    </div>
  );
};
