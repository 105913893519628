import { createContext, useEffect, useState } from "react";
import { Toast } from "../components";

const ToastContext = createContext();

const ToastProvider = ({ children }) => {
  const [toast, setToast] = useState(false);

  useEffect(() => {
    if (toast) {
      setTimeout(() => {
        setToast(false);
      }, 5 * 1000);
    }
    return () => {
      clearTimeout();
    };
  }, [toast]);

  const showToast = () => {
    setToast(true);
  };

  return (
    <ToastContext.Provider
      value={{
        toast,
        showToast,
      }}
    >
      {children}
      {toast && <Toast value={"Copied!"} />}
    </ToastContext.Provider>
  );
};

export { ToastContext, ToastProvider };
