import "./onboarding.css";
import { Button } from "../../components";
import { useState } from "react";

const shuffle = (array = []) => {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex > 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
};

export const Verification = ({ mnemonic, onContinue }) => {
  const [phrasesList] = useState(mnemonic.split(" "));
  const [mnemonicList] = useState(shuffle(mnemonic.split(" ")));
  const [firstWord, setFirstWord] = useState(null);
  const [lastWord, setLastWord] = useState(null);
  const disabled = firstWord !== phrasesList[0] || lastWord !== phrasesList[phrasesList.length - 1];

  const setWord = (word) => {
    if (!firstWord) setFirstWord(word);
    else if (firstWord === word) setFirstWord(null);
    else if (lastWord === word) setLastWord();
    else setLastWord(word);
  };

  return (
    <>
      <div className="content">
        <div className="title">You saved it, right?</div>
        <div className="">
          Verify that you saved your secret recovery phrase by tapping the first (1st) then last
          (24th) word.
        </div>

        <div className="phrase-container">
          {mnemonicList.map((el, index) => {
            return (
              <div key={index} className="phrase-content">
                {firstWord === el && <div className="marker">1st</div>}
                {lastWord === el && <div className="marker">{mnemonicList.length}th</div>}
                <button
                  className={`phrase-box 
                ${firstWord === el && "first-word"} 
                ${lastWord === el && "last-word"}`}
                  onClick={() => setWord(el)}
                >
                  {el}
                </button>
              </div>
            );
          })}
        </div>
      </div>

      <Button label="Confirm" disabled={disabled} action={onContinue} />
    </>
  );
};
