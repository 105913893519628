import "./navHeader.css";
import { useNavigate } from "react-router";
import { Back } from "../back";

export const NavHeader = ({ label, action }) => {
  const navigate = useNavigate();

  const doAction = () => {
    if (action) action();
    else navigate(-1);
  };

  return (
    <div className="nav-header">
      <Back action={doAction} />
      <span className="subtitle capitalize">{label}</span>
    </div>
  );
};
