// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.receive-network {
  display: flex;
  border: 1px solid #5c5c5e;
  padding: 18px 22px;
}

.receive-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.receive-info {
  flex: 1;
}

.receive-description {
  font-size: 16px;
}

.receive-address {
  font-size: 18px;
  padding: 15px 0;
}

.receive-actions {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.receive-btn {
  background: #292c2c;
  border: none;
  padding: 15px;
}

.center {
  text-align: center;
}

.hint {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
}

.text-18 {
  font-size: 18px;
}

.grey {
  color: #5c5c5e;
}

.receive-drawer-address {
  display: flex;
  flex-direction: column;
  border: 1px solid #5c5c5e;
  padding: 16px 22px;
  gap: 7px;
}

.receive-drawer-inline {
  display: inline-flex;
  gap: 5px;
  font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/transactions/receive/receive.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,kBAAkB;EAClB,QAAQ;AACV;;AAEA;EACE,oBAAoB;EACpB,QAAQ;EACR,eAAe;AACjB","sourcesContent":[".receive-network {\n  display: flex;\n  border: 1px solid #5c5c5e;\n  padding: 18px 22px;\n}\n\n.receive-list {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}\n\n.receive-info {\n  flex: 1;\n}\n\n.receive-description {\n  font-size: 16px;\n}\n\n.receive-address {\n  font-size: 18px;\n  padding: 15px 0;\n}\n\n.receive-actions {\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n}\n\n.receive-btn {\n  background: #292c2c;\n  border: none;\n  padding: 15px;\n}\n\n.center {\n  text-align: center;\n}\n\n.hint {\n  text-align: center;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 140%;\n}\n\n.text-18 {\n  font-size: 18px;\n}\n\n.grey {\n  color: #5c5c5e;\n}\n\n.receive-drawer-address {\n  display: flex;\n  flex-direction: column;\n  border: 1px solid #5c5c5e;\n  padding: 16px 22px;\n  gap: 7px;\n}\n\n.receive-drawer-inline {\n  display: inline-flex;\n  gap: 5px;\n  font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
